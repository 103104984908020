<template>
  <div class="flex flex-col w-full h-full items-center bg-tl-green-100 rounded-20px p-40px">
    <VideoModule
      :trim="state.currentTrim"
      :session="sessionGetters.selectedSession.value?.session ?? 1"
      :group="sessionGetters.selectedGroup.value?.name ?? 'group1'"
      :video-index="1"
      @timeupdate="(time: number) => (state.currentTime = time)"
      @trimupdate="(trim: number[]) => (state.initialTrim = trim)"
    >
    </VideoModule>
  </div>
</template>

<script setup lang="ts">
import { reactive, type PropType } from 'vue'
import VideoModule from '@/components/nursing/VideoModule.vue'
import { onMounted } from 'vue'
import { useSessionStore } from '@/composition/stores/sessionStore.js'

interface State {
  currentTime: number
  currentTrim: number[]
  initialTime: number
  initialTrim: number[]
}

const { actions: sessionActions, getters: sessionGetters } = useSessionStore()

const state: State = reactive({
  currentTime: 0,
  currentTrim: [0, 0],
  initialTime: 0,
  initialTrim: [0, 0]
})

defineProps({
  time: { type: Number, required: true },
  trim: { type: Object as PropType<number[]>, required: true }
})

onMounted(() => {
  const groupName = sessionGetters.selectedGroup.value?.name || 'group1'
  const session = sessionGetters.selectedSession.value
  sessionActions.getDataCSV(groupName, session?.session ?? 1)
})
</script>
