<template>
  <div class="relative flex flex-col h-screen p-12 bg-zinc-800 text-stone-200 overflow-y-scroll">
    <div v-if="router.currentRoute.value.path.includes('legal')">
      Welcome to admin page. Here you can upload files for meeting and document analytics

      <div
        @click="router.push('/postlogin')"
        class="absolute p-2 right-2 top-5 text-white font-xl bg-zinc-700 rounded-lg cursor-pointer opacity-80 hover:opacity-100"
      >
        Back
      </div>

      <div class="flex flex-col space-y-4 m-8">
        <div class="flex space-x-2">
          <div>Select construct</div>
          <div class="text-gray-900 font-light">
            <select class="rounded-lg" v-model="path.selectedMode">
              <option disabled value="">Please select one</option>
              <option v-for="(team, index) in MODE" :key="index">{{ team }}</option>
            </select>
          </div>
        </div>
        <div class="flex space-x-2">
          <div>Select the team</div>
          <div class="text-gray-900 font-light">
            <select class="rounded-lg" v-model="path.selectedTeam">
              <option disabled value="">Please select one</option>
              <option v-for="(team, index) in TEAMS" :key="index">{{ team }}</option>
            </select>
          </div>
        </div>
        <div class="flex">
          <div class="">
            <input
              class="rounded-lg"
              @change="getFileInputValue"
              ref="file"
              type="file"
              id="formFile"
              accept="application/msword,.vtt,text/plain,.docx"
            />
          </div>
        </div>
        <div>
          <SlButton
            class="bg-zinc-700 rounded-lg cursor-pointer opacity-80 hover:opacity-100 p-2"
            @click="UploadFile"
            type="submit"
            >Upload</SlButton
          >
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-white">nursing admin</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, type Ref } from 'vue'
import { useTeamStore } from '@/composition/stores/teamStore.js'
import router from '@/router'
import { TEAMS } from '@/constants'
import SlButton from '@/components/base/SlButton.vue'
import type { IPath } from '@/models/legal'

const { actions: teamActions } = useTeamStore()

const audioNode = ref('')
const path: Ref<IPath> = ref({
  selectedTeam: '',
  selectedMode: ''
})

let files: FileList
const MODE = ['Meeting', 'Document']
const getFileInputValue = (event: Event) => {
  //get the file input value
  const target = event.target as HTMLInputElement
  files = target.files as FileList
  //assign it to our reactive reference value
  audioNode.value = URL.createObjectURL(files[0])
}
const UploadFile = async () => {
  const formData = new FormData()
  formData.append('file', files[0])
  teamActions.uploadFile(formData, path.value)
}
</script>
