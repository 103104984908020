<template>
  <div class="pt-8">
    <div class="mb-4 rounded-40px" v-for="(doc, index) of feedback" :key="index">
      <div class="row flex-row px-16 py-8 rounded-t-20px bg-tl-grey-800 dark:bg-opacity-5">
        <!-- Document Name -->
        <div class="flex flex-row text-black dark:text-white font-normal text-2xl">
          {{ doc.filename }}
        </div>

        <!-- Created Date -->
        <!-- <div class="flex flex-row text-white/50 text-sm">Created {{ file.date }}</div> -->
      </div>
      <div class="row flex-row px-16 p-8 bg-tl-grey-900 dark:bg-opacity-5 rounded-b-20px">
        <!-- Document Summary -->
        <div class="flex flex-row">
          <div class="flex flex-col">
            <!--Document Icon-->
            <img class="h-7" :src="DocumentIcon" />
          </div>
          <div class="flex flex-col text-black dark:text-white font-semibold text-lg ml-2 my-auto">
            Document Summary
          </div>
        </div>

        <div
          class="text-black dark:text-white overflow-hidden"
          :class="{ 'max-h-10': toggleReadMore[index] }"
          v-html="doc.summary"
        ></div>

        <!-- Read More/Hide Button -->
        <div class="flex flex-row mt-1">
          <div class="flex flex-col">
            <button
              id="toggle-btn"
              class="text-tl-lilla-300 font-semibold focus:outline-none text-decoration-line: underline py-5"
              @click="toggle(index)"
            >
              {{ toggleReadMore[index] ? 'View More' : 'Hide' }}
            </button>
          </div>
        </div>
        <!-- ChatGPT Feedback -->
        <div class="flex flex-row mt-4 mb-4">
          <div class="flex flex-col">
            <!--Document Icon-->
            <img class="h-7" :src="ChatGPTFeebackIcon" />
          </div>
          <div class="flex flex-col text-black dark:text-white font-semibold text-lg ml-2 my-auto">
            ChatGPT Assessment
          </div>
        </div>

        <div class="flex flex-row text-black dark:text-white font-normal pb-8">
          {{ doc.feedback }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue'
import { useTeamStore } from '@/composition/stores/teamStore.js'
import DocumentIcon from '@/assets/icons/svg/documents.svg'
import ChatGPTFeebackIcon from '@/assets/icons/svg/gptfeedback.svg'
import { PROMPTSECTION } from '@/constants'

interface Toggle {
  [key: string]: boolean
}

const teamStore = useTeamStore()
const toggleReadMore: Ref<Toggle> = ref({})
const feedback: Ref<DocFeedback[]> = ref([])

interface DocFeedback {
  filename: string
  date: string
  summary: string
  feedback: string
}

function toggle(index: number) {
  const name = index.toString()
  if (!toggleReadMore.value[name]) {
    toggleReadMore.value[name] = true
  } else {
    toggleReadMore.value[name] = false
  }
}
/* const gptDocResponse = computed(() => {
  const responseMap: Map<string, DocFeedback> = new Map()
  const feedback = teamStore.getters.taskFeedback.value
  feedback.forEach((item) => {
    let rm = responseMap.get(item.filename)
    if (!rm) {
      rm = {
        filename: item.filename,
        date: '', // TODO: Is this data available?
        summary: '',
        feedback: ''
      }
      responseMap.set(item.filename, rm)
    }
    if (item.section === PROMPTSECTION.Summary) {
      rm.summary = item.filefeedback
    } else if (item.section === PROMPTSECTION.Feedback) {
      rm.feedback = item.filefeedback
    }
  })
  return responseMap.values()
}) */

onMounted(() => {
  const f = teamStore.getters.taskFeedback.value
  const feedbackMap = new Map()
  f.forEach((item) => {
    let rm = feedbackMap.get(item.filename)
    if (!rm) {
      rm = {
        filename: item.filename,
        date: '', // TODO: Is this data available?
        summary: '',
        feedback: ''
      }
      feedbackMap.set(item.filename, rm)
    }
    if (item.section === PROMPTSECTION.Summary) {
      rm.summary = item.filefeedback
    } else if (item.section === PROMPTSECTION.Feedback) {
      rm.feedback = item.filefeedback
    }
  })
  feedback.value = Array.from(feedbackMap.values())
})
</script>
