<template>
  <div
    class="bg-tl-green-100 relative p-4 w-full rounded-40px flex justify-center flex-row flex-wrap"
  >
    <div v-if="!state.drillDown" class="flex flex-col justify-around items-center w-full">
      <slot name="header"></slot>
      <div class="flex flex-row justify-around items-center w-full">
        <div
          class="flex flex-col justify-center items-center"
          v-for="(event, index) in sessionGetters.events.value"
          :key="index"
          @click="showDrillDown(event)"
        >
          <div
            class="bg-tl-grey-100 text-white dark:text-black dark:bg-white text-center px-2 py-1 rounded-2xl mb-3 w-32"
          >
            Event {{ index + 1 }}
          </div>
          <p class="mb-2 text-white">{{ event.description }}</p>
          <NetworkChart
            v-if="state.links[event.id]"
            class="max-h-96 h-96"
            :actors="state.actorData[event.id]"
            :links="state.links[event.id]"
            :showKey="false"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex w-full justify-between items-start">
      <div
        class="border rounded-full absolute -top-1 -left-1 p-2 bg-gray-50 dark:bg-tl-grey-100"
        @click="state.drillDown = false"
      >
        <img class="w-3 rotate-90 dark:invert" :src="ArrowIcon" />
      </div>
      <div class="flex flex-col w-3/5 items-center">
        <TranscriptView
          v-if="selectedEvent"
          :time="state.currentTime"
          :trim="trim"
          :data="state.utterances[selectedEvent.id]"
          class="mr-5"
        />
        <NetworkChart
          v-if="selectedEvent"
          class="max-h-72 h-72"
          :actors="state.actorData[selectedEvent.id]"
          :links="state.links[selectedEvent.id]"
        />
      </div>
      <VideoModule
        class="w-2/5"
        :session="sessionGetters.selectedSession.value?.session ?? 1"
        :group="sessionGetters.selectedGroup.value?.name ?? 'group1'"
        @timeupdate="(time: number) => (state.currentTime = time)"
      ></VideoModule>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import TranscriptView, { type TranscriptRow } from '@/components/nursing/TranscriptView.vue'
import NetworkChart, { type LinkData, type ActorData } from '@/components/charts/NetworkChart.vue'
import VideoModule from '@/components/nursing/VideoModule.vue'
import type { TLEvent } from '@/models'
import { colorsForActors, EventType } from '@/constants'
import { parseTLTime } from '@/utilities'
import ArrowIcon from '@/assets/icons/fontawesome/arrow-down-regular.svg'

const { state: sessionState, getters: sessionGetters, actions: sessionActions } = useSessionStore()

const messages = {
  en: {
    LN: 'Lead Nurse',
    SN1: 'Support Nurse 1',
    SN2: 'Support Nurse 2',
    HR: 'Handover Responsible',
    S5: 'Student 4',
    S6: 'Student 5',
    None: 'None'
  }
}
const { t } = useI18n({ messages })

defineProps({
  time: { type: Number, required: true },
  trim: { type: Object as PropType<number[]>, required: true }
})

interface State {
  currentTime: number
  actorData: { [key: string]: ActorData[] }
  links: { [key: string]: LinkData[] }
  utterances: { [key: string]: TranscriptRow[] }
  drillDown: boolean
}

const state: State = reactive({
  currentTime: 0,
  actorData: {},
  links: {},
  utterances: {},
  drillDown: false
})

onMounted(() => {
  getTabData()
})

const selectedEvent = sessionGetters.selectedEvent

function showDrillDown(event: TLEvent) {
  state.drillDown = true
  sessionActions.setSelectedEvent(event)
}

watch(selectedEvent, () => {
  if (selectedEvent.value) showDrillDown(selectedEvent.value)
})

watch(sessionGetters.events, () => getTabData())

const getTabData = () => {
  state.drillDown = false
  const utterances = Array.from(sessionState.csvData)
  sessionGetters.events.value.forEach((event) => {
    const actors: ActorData[] = [] // unique actor names
    const links: LinkData[] = []

    state.utterances[event.id] = utterances
      .slice(event.startRow, 1 + (event.endRow ?? 0))
      .map((d) => {
        return {
          speaker: d.Speaker,
          receiver: d.Receiver,
          utterance: d.Utterance,
          start: parseTLTime(d.Start),
          end: parseTLTime(d.End),
          code: event.type === EventType.Loop ? d['Loop Code'] : d['Task Code']
        }
      })

    for (let data of event.codes) {
      const speakerKey = data.speaker as keyof typeof colorsForActors
      const receiverKey = data.receiver as keyof typeof colorsForActors
      if (!actors.some((a) => a.id === speakerKey)) {
        actors.push({
          id: speakerKey,
          label: t(speakerKey),
          colour: colorsForActors[speakerKey].colour ?? '#AA00BB'
        })
      }
      if (!actors.some((a) => a.id === receiverKey)) {
        actors.push({
          id: receiverKey,
          label: t(receiverKey),
          colour: colorsForActors[receiverKey].colour ?? '#AA00BB'
        })
      }

      links.push({
        source: speakerKey,
        target: receiverKey,
        label: data.code
      })
    }

    // actorData and links are set equally independent of the `selectedEvent` state
    state.actorData[event.id] = actors
    state.links[event.id] = links
  })
}
</script>
