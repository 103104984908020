<template>
  <div class="mx-5 dark:bg-tl-grey-100 rounded-md mb-4 flex flex-col">
    <div class="flex flex-row justify-between m-2 cursor-pointer">
      <div class="flex">
        <div
          class="rounded-l-md p-2 border border-black border-r-2 border-r-white dark:border-r-black"
          :class="[
            state.viewMode === ViewMode.group ? 'bg-white text-black' : 'bg-tl-grey-100  text-white'
          ]"
          @click="selectMode('group')"
        >
          Group
        </div>
        <div
          class="rounded-r-md p-2 border border-black border-r-1 border-l-white dark:border-l-black"
          :class="[
            state.viewMode === ViewMode.individual
              ? 'bg-white text-black'
              : 'bg-tl-grey-100  text-white'
          ]"
          @click="selectMode('individual')"
        >
          Individual
        </div>
      </div>
      <div v-if="state.viewMode === ViewMode.individual" class="flex">
        <div
          v-for="(student, index) in state.heartData.map((d) => d.student as Speaker)"
          :key="index"
          class="rounded-full w-22 mx-2 p-2 border border-black"
          :class="[
            selectedSpeaker === student ? 'bg-white text-black' : 'bg-tl-grey-100  text-white'
          ]"
          @click="setSpeaker(student)"
        >
          {{ t(student) }}
        </div>
      </div>
    </div>
    <div v-if="state.viewMode === ViewMode.group" class="grid grid-cols-2 gap-4 flex-shrink p-2">
      <template v-for="chartData in state.heartData" :key="chartData.student">
        <LineChart
          class="h-72"
          :time="state.currentTime"
          :events="[]"
          :line="chartData"
          :darkmode="darkMode"
        ></LineChart>
      </template>
    </div>
    <div v-else class="flex flex-row m-2">
      <div class="flex flex-col mr-2 flex-grow w-1/2">
        <template v-for="chartData in state.heartData" :key="`chart-heart-${chartData.student}`">
          <LineChart
            v-if="selectedSpeaker === chartData.student"
            class="h-72"
            :time="state.currentTime"
            :events="eventOverlays"
            :line="chartData"
            :darkmode="darkMode"
          ></LineChart>
        </template>
        <template
          v-for="chartData in state.accelerationData"
          :key="`chart-acc-${chartData.student}`"
        >
          <LineChart
            v-if="selectedSpeaker === chartData.student"
            class="h-72 mt-2"
            :time="state.currentTime"
            :events="[]"
            :line="chartData"
            :darkmode="darkMode"
          ></LineChart>
        </template>
      </div>
      <VideoModule
        :session="sessionGetters.selectedSession.value?.session ?? 1"
        :group="sessionGetters.selectedGroup.value?.name ?? 'group1'"
        @timeupdate="(time: number) => (state.currentTime = time)"
      ></VideoModule>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, watch, type ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import { useAppStore } from '@/composition/stores/appStore.js'
import useApiService from '@/api/apiRequest.js'
import VideoModule from '@/components/nursing/VideoModule.vue'
import {
  TabName,
  EventType,
  EventStatus,
  Speaker,
  columnDefs,
  ErrorCode,
  XHR_REQUEST_TYPE
} from '@/constants'
import type { EventMarker, Line } from '@/components/charts/LineChart.vue'
import LineChart from '@/components/charts/LineChart.vue'
import type { TLEvent } from '@/models/nursing'
import type { APIRequestPayload } from '@/models'

enum ViewMode {
  group = 'group',
  individual = 'individual'
}

type ColumnNames =
  | 'Time'
  | 'LN'
  | 'SN1'
  | 'SN2'
  | 'HR'
  | 'S5'
  | 'S6'
  | 'LN - Mag'
  | 'SN1 - Mag'
  | 'SN2 - Mag'
  | 'HR - Mag'
  | 'S5 - Mag'
  | 'S6 - Mag'

type ThresholdColumns = 'Speaker' | 'Threshold'

interface State {
  currentTime: number
  accelerationData: Line[]
  heartData: Line[]
  viewMode: ViewMode
  checkedNames: string[]
}

const { getters: sessionGetters, actions: sessionActions } = useSessionStore()
const { getters: appGetters } = useAppStore()
const apiService = useApiService()
const session = sessionGetters.selectedSession
const darkMode = appGetters.darkMode
let data: Record<ColumnNames, number>[]
const thresholds: Record<Speaker, number> = {
  [Speaker.LeadNurse]: 0,
  [Speaker.SupportNurse1]: 0,
  [Speaker.SupportNurse2]: 0,
  [Speaker.HandoverResponsible]: 0,
  [Speaker.S5]: 0,
  [Speaker.S6]: 0,
  [Speaker.None]: 0,
  [Speaker.Patient]: 0,
  [Speaker.All]: 0
}

const messages = {
  en: {
    LN: 'Lead Nurse',
    SN1: 'Support Nurse 1',
    SN2: 'Support Nurse 2',
    HR: 'Handover Responsible',
    S5: 'Student 4',
    S6: 'Student 5',
    None: 'None'
  }
}
const { t } = useI18n({ messages })

const state: State = reactive({
  currentTime: 0,
  accelerationData: [],
  heartData: [],
  viewMode: ViewMode.group,
  checkedNames: []
})

const selectedSpeaker = sessionGetters.selectedSpeaker
const selectedEvent = sessionGetters.selectedEvent

const heartMap = new Map<string, Line>()
const accMap = new Map<string, Line>()

watch(
  () => session.value,
  async () => {
    await callData()
    if (data) setupChart()
  }
)

onMounted(async () => {
  await callData()
  if (data) setupChart()
  setupEvents()
})

function selectMode(name: string) {
  if (name === 'group') {
    state.viewMode = ViewMode.group
    sessionActions.setSpeaker(Speaker.None)
  } else if (name === 'individual') {
    state.viewMode = ViewMode.individual
    sessionActions.setSpeaker(Speaker.LeadNurse)
  }
}

async function setupEvents() {
  const iter = heartMap.values()
  const events: TLEvent[] = []
  let currentEvent: TLEvent | undefined

  let result = iter.next()
  while (!result.done) {
    let index = 1
    const data = result.value.data
    const owner = result.value.student
    const threshold = thresholds[owner]
    data.forEach((lineData, index2) => {
      if (lineData.y > threshold) {
        if (currentEvent) currentEvent.end = lineData.x
        else {
          currentEvent = {
            id: `event-${result.value?.student}-${index}-${index2}`,
            type: EventType.Stress,
            name: `Event ${index}`,
            status: EventStatus.None,
            owner,
            description: '',
            codes: [],
            start: lineData.x,
            end: lineData.x
          }
        }
      } else if (currentEvent) {
        events.push(currentEvent)
        currentEvent = undefined
        index++
      }
    })
    result = iter.next()
  }
  sessionActions.setEvents(TabName.StressManagement, events)
}

function setSpeaker(speaker: Speaker) {
  sessionActions.setSpeaker(speaker)
}

const eventOverlays: ComputedRef<EventMarker[]> = computed(() => {
  const evs = sessionGetters.events.value
    .filter((e) => e.owner == selectedSpeaker.value)
    .filter((e) => e.id == selectedEvent.value?.id)
  return evs.map((e, i) => ({
    key: `event-marker-key-${i}`,
    start: e.start,
    end: e.end ?? -1,
    text: e.name
  }))
})

async function callData() {
  if (session.value) {
    const s = session.value

    const payload: APIRequestPayload = {
      errorCode: ErrorCode.NURSING,
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      route: `/api/file?group=${s.group}&session=${s.session}&module=StressManagement&index=0`,
      convertDates: false
    }
    const res = await apiService.base.apiRequest<Record<string, string>[]>(payload)
    if (res.data) data = res.data.map((d) => parseRow(d))

    payload.route = `/api/file?group=${s.group}&session=${s.session}&module=StressManagement&index=1`
    const res2 = await apiService.base.apiRequest<Record<ThresholdColumns, string>[]>(payload)
    if (res2.data) {
      res2.data.forEach((d) => {
        const key = translateSpeaker(d['Speaker'])
        thresholds[key] = parseFloat(d['Threshold'])
      })
    }
  }
}

function parseRow(d: Record<string, string>) {
  let newRow: Record<ColumnNames, number> = {
    Time: parseInt(d['Time']),
    LN: parseFloat(d['S1 - HR']),
    'LN - Mag': parseFloat(d['S1 - Mag']),
    SN1: parseFloat(d['S2 - HR']),
    'SN1 - Mag': parseFloat(d['S1 - Mag']),
    SN2: parseFloat(d['S3 - HR']),
    'SN2 - Mag': parseFloat(d['S3 - Mag']),
    HR: parseFloat(d['S4 - HR']),
    'HR - Mag': parseFloat(d['S4 - Mag']),
    S5: parseFloat(d['S5 - HR']),
    'S5 - Mag': parseFloat(d['S5 - Mag']),
    S6: parseFloat(d['S6 - HR']),
    'S6 - Mag': parseFloat(d['S6 - Mag'])
  }
  return newRow
}

function translateSpeaker(speaker: string) {
  switch (speaker) {
    case '0':
      return Speaker.LeadNurse
    case '1':
      return Speaker.SupportNurse1
    case '2':
      return Speaker.SupportNurse2
    case '3':
      return Speaker.HandoverResponsible
    case '4':
      return Speaker.S5
    case '5':
      return Speaker.S6
    default:
      return Speaker.None
  }
}

async function setupChart() {
  if (session.value) {
    state.accelerationData = []
    state.heartData = []

    heartMap.clear()
    accMap.clear()

    data.forEach((row) => {
      columnDefs.forEach((entry) => {
        // Initial value
        let sItem: Line = {
          key: entry.name,
          student: entry.name.slice(0, 3).trim() as Speaker,
          colour: entry.colour,
          yBounds: [0, 0],
          labels: ['x', 'y'],
          data: []
        }

        if (entry.name.includes('- Mag')) {
          if (accMap.has(entry.name)) sItem = accMap.get(entry.name)!
          else accMap.set(entry.name, sItem)
          sItem.yBounds = [400, 1800]
          sItem.labels = ['Time', 'Acceleration Magnitude']
        } else {
          if (heartMap.has(entry.name)) sItem = heartMap.get(entry.name)!
          else heartMap.set(entry.name, sItem)
          sItem.yBounds = [60, 130]
          sItem.labels = ['Time', 'Heart Rate']
        }
        const y = row[entry.name as ColumnNames]
        sItem.data.push({
          x: row.Time,
          y: isNaN(y) ? 0 : y
        })
      })
    })

    columnDefs.forEach((entry) => {
      const accItem = accMap.get(entry.name)
      if (accItem) state.accelerationData.push(accItem)
      const heartItem = heartMap.get(entry.name)
      if (heartItem) state.heartData.push(heartItem)
    })
  }
}
</script>
