import { computed, ref, type ComputedRef, type Ref } from 'vue'

import useApiService from '@/api/apiRequest.js'
import { useTeamStore } from './teamStore.js'
import type { IMeetingInfo, IMeetingResponse } from '@teams'
import type { APIRequestPayload } from '@/models/main.js'
import { ErrorCode, XHR_REQUEST_TYPE } from '@/constants.js'
import { Duration, Turns, type Agg, type IPieData } from '@/models/legal.js'

const apiService = useApiService()
const { getters: teamGetters } = useTeamStore()

async function fetchmeetingData() {
  const payload: APIRequestPayload = {
    errorCode: ErrorCode.TEAMS,
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/meetings/meeting',
    query: {
      team_id: teamGetters.selectedTeam.value as string
    }
  }
  return apiService.base.apiRequest<IMeetingResponse>(payload)
}

interface State {
  // meetingData: IMeetingResponse | undefined
  speakingturn: IPieData[]
  duration: IPieData[]
  speakingInfo: IMeetingInfo[]
  aggSpeakingInfo: Agg[]
}
const state: Ref<State> = ref({
  meetingData: undefined,
  speakingturn: [],
  duration: [],
  speakingInfo: [],
  aggSpeakingInfo: []
})

interface Getters {
  // meetingData: ComputedRef<IMeetingResponse | undefined>
  speakingturn: ComputedRef<IPieData[]>
  duration: ComputedRef<IPieData[]>
  speakingInfo: ComputedRef<IMeetingInfo[]>
  aggSpeakingInfo: ComputedRef<Agg[]>
}

const getters = {
  /*get meetingData(): ComputedRef<IMeetingResponse | undefined> {
    return computed(() => state.value.meetingData)
  },*/
  get speakingturn(): ComputedRef<IPieData[]> {
    return computed(() => state.value.speakingturn)
  },
  get duration(): ComputedRef<IPieData[]> {
    return computed(() => state.value.duration)
  },
  get speakingInfo(): ComputedRef<IMeetingInfo[]> {
    return computed(() => state.value.speakingInfo)
  },
  get aggSpeakingInfo(): ComputedRef<Agg[]> {
    return computed(() => state.value.aggSpeakingInfo)
  }
}
interface Actions {
  getMeetingData: () => Promise<void>
  getPiechart: (meeting_name: string) => Promise<void>
}
const actions = {
  getMeetingData: async function (): Promise<void> {
    const response = await fetchmeetingData()
    const meetingInfo = response.data
    const meetingAnalysis: IMeetingResponse = {
      agg: [],
      meeting: []
    }
    if (meetingInfo) {
      meetingAnalysis.agg = meetingInfo.agg
      meetingAnalysis.meeting = meetingInfo.meeting

      state.value.speakingInfo = []
      state.value.aggSpeakingInfo = meetingAnalysis.agg

      meetingAnalysis.meeting.forEach((item) => {
        const mappedFile = teamGetters.meetingFeedback.value.find(
          (feedback) => feedback.filename == item.filename
        )
        if (mappedFile) item.filefeedback = mappedFile.filefeedback
        if (item.speakinginfo.length) {
          item.type = 'online'
        } else {
          item.type = 'physical'
        }
        state.value.speakingInfo.push(item)
      })
    }
  },

  getPiechart: async function (meeting_name: string): Promise<void> {
    state.value.speakingturn = []
    state.value.duration = []
    const selectedMeeting = state.value.speakingInfo.find((item) => item.filename == meeting_name)
    if (selectedMeeting) {
      selectedMeeting.speakinginfo.forEach((item) => {
        state.value.speakingturn.push(new Turns(item))
        state.value.duration.push(new Duration(item))
      })
    }

    return Promise.resolve()
  }
}
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
// This defines the interface used externally
export function useMeetingStore(): ServiceInterface {
  return {
    getters,
    actions
  }
}

export type useMeetingStore = ReturnType<typeof useMeetingStore>
