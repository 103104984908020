<template>
  <div
    class="flex flex-col w-full h-full justify-start items-start p-11 bg-white dark:bg-tl-grey-100"
  >
    <div class="flex flex-col">
      <h1 class="text-black dark:text-white text-4xl w-1/2">
        Overview of
        <span class="text-tl-lilla-300">{{ selectedTeam }}'s</span>
        collaboration on the project report in Teams.
      </h1>
      <p class="mt-11 text-tl-grey-300 w-1/2">
        By clicking on each diagram or chart you can drill down for more details from online
        meetings, project documents and Teams data.
      </p>
    </div>
    <div class="mt-16 w-auto flex justify-between items-start">
      <div
        class="w-64 m-2 p-5 rounded-xl bg-tl-grey-300 dark:bg-tl-grey-200 flex flex-col items-center cursor-pointer"
        v-for="(type, index) in Object.values(AnalyticsType)"
        :key="index"
        @click="routeTo(type)"
      >
        <div class="flex flex-col justify-start w-full">
          <!--span class="text-tl-lilla-400">Lorem ipsum</span-->
          <p class="text-white text-lg">{{ t(type) }}</p>
        </div>
        <div
          class="rounded-full flex justify-center items-center bg-tl-lilla-400 h-48 w-48 align-self-center mt-5 mb-2"
        >
          <img class="h-20 invert dark:invert-0" :src="iconByAnalyticsType(type)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTeamStore } from '@/composition/stores/teamStore.js'
import { AnalyticsType } from '@/constants'

import MeetingIcon from '@/assets/icons/fontawesome/user-group-light.svg'
import CollaborationIcon from '@/assets/icons/fontawesome/file-pen-regular.svg'
import DocumentsIcon from '@/assets/icons/fontawesome/newspaper-light.svg'
import router from '@/router'

const messages = {
  no: {
    [AnalyticsType.MeetingAnalytics]: 'Meeting Analytics',
    [AnalyticsType.CollaborationExperience]: 'Collaboration Experience',
    [AnalyticsType.WorkDocuments]: 'Work Documents'
  },
  en: {
    [AnalyticsType.MeetingAnalytics]: 'Meeting Analytics',
    [AnalyticsType.CollaborationExperience]: 'Collaboration Experience',
    [AnalyticsType.WorkDocuments]: 'Work Documents'
  }
}
const { t } = useI18n({ messages })

const teamStore = useTeamStore()
//const meetingStore = useMeetingStore()

onMounted(async () => {
  selectedTeam.value = teamStore.getters.selectedTeam.value ?? ''
})

const selectedTeam: Ref<string> = ref('')

function iconByAnalyticsType(type: AnalyticsType) {
  switch (type) {
    case AnalyticsType.CollaborationExperience:
      return CollaborationIcon
    case AnalyticsType.MeetingAnalytics:
      return MeetingIcon
    default:
      return DocumentsIcon
  }
}

function routeTo(type: AnalyticsType) {
  const index = Object.values(AnalyticsType).findIndex((t) => t === type)
  const value = Object.keys(AnalyticsType)[index]
  router.push({ name: value })
}
</script>
