<template>
  <div
    class="h-screen w-screen flex flex-col justify-center items-center bg-white dark:bg-tl-grey-100 text-black dark:text-white"
  >
    <h1 class="text-3xl text-tl-green-200 dark:text-tl-green-400">Welcome to Teamlearn</h1>
    <div class="flex flex-row justify-around w-1/2 pt-12 text-black dark:text-white text-3xl">
      <div
        class="cursor-pointer border-2 border-black dark:border-white rounded-lg p-2"
        @click="legal()"
      >
        LegalTech
      </div>
      <div
        class="cursor-pointer border-2 border-black dark:border-white rounded-lg p-2"
        @click="legalTestdata()"
      >
        LegalTech - Test Data
      </div>
      <div
        class="cursor-pointer border-2 border-black dark:border-white rounded-lg p-2"
        @click="nursing()"
      >
        Nursing
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import router from '@/router'
import { useUserStore } from '@/composition/stores/userStore'
import useApiService from '@/api/apiRequest.js'
import { UserRole } from '@database'
import type { APIRequestPayload } from '@/models'
import { ErrorCode, XHR_REQUEST_TYPE } from '@/constants'
import { onMounted } from 'vue'
const userStore = useUserStore()
const apiService = useApiService()

onMounted(async () => {
  const isAdmin = userStore.getters.user.value?.profile.role === UserRole.admin

  if (!isAdmin) {
    // Check for Nursing membership
    const payload: APIRequestPayload = {
      errorCode: ErrorCode.NURSING,
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      route: `/api/ismember`,
      convertDates: false
    }
    const res = await apiService.base.apiRequest<{ member: string }>(payload)
    if (res.data && res.data.member) router.push('/nursing/groups')
    else router.push('/legal')
  }
})

function legal() {
  router.push('/legal')
}
function legalTestdata() {
  router.push('/legal?testing=true')
}
function nursing() {
  router.push('/nursing/groups')
}
</script>
