<template>
  <div class="relative bg-white dark:bg-tl-grey-100 rounded-md" :id="'lineContainer-' + cid">
    <div :id="'lineChart-' + cid" class=""></div>
    <div :id="'legend-' + cid" class="ml-7 absolute top-0 right-4">
      <div class="flex flex-row items-center text-white text-sm font-normal mb-4 last:mb-0">
        <div
          class="w-2.5 h-2.5 rounded-full mr-1.5"
          :style="{ 'background-color': line.colour }"
        ></div>
        <p>{{ line.student }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useId, onMounted, onUnmounted, toRefs, watch, type PropType } from 'vue'
import * as d3 from 'd3'
import type { Speaker } from '@/constants'

export interface LineData {
  x: number
  y: number
}

export interface Line {
  key: string
  student: Speaker
  colour: string
  yBounds: [number, number]
  labels: [string, string]
  data: LineData[]
}

export interface EventMarker {
  key: string
  start: number
  end: number
  text: string
}

const props = defineProps({
  time: { type: Number, required: true },
  line: { type: Object as PropType<Line>, required: true }, // Data structure for multiple lines
  darkmode: { type: Boolean, default: false },
  events: {
    type: Object as PropType<EventMarker[]>,
    default: () => {
      return []
    }
  }
})

const { line, time, events, darkmode } = toRefs(props)
const cid = useId()
const margin = 25

let svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>
let aLines: d3.Selection<SVGGElement, unknown, HTMLElement, any>

onMounted(() => {
  if (line.value.data.length) setupChart()
  window.addEventListener('resize', setupChart)
})

onUnmounted(() => {
  window.addEventListener('resize', setupChart)
})

let currentHeight = 0
let currentWidth = 0

// Scaling
let minX = 0
let maxX = 0
let scaleX: d3.ScaleLinear<number, number, never>
let scaleY: d3.ScaleLinear<number, number, never>

function setupChart() {
  d3.select(`#lineChart-${cid}`).select('svg').remove()

  currentHeight = parseInt(d3.select(`#lineContainer-${cid}`).style('height'), 10)
  currentWidth = parseInt(d3.select(`#lineContainer-${cid}`).style('width'), 10)

  // Scaling
  const minMax = d3.extent(line.value.data, (d) => d.x)
  minX = minMax[0] ?? 0
  maxX = minMax[1] ?? 0

  scaleX = d3
    .scaleLinear()
    .domain([minX!, maxX!])
    .range([0, currentWidth - margin])

  //const [minY, maxY] = d3.extent(line.value.data, (d) => d.y)
  scaleY = d3
    .scaleLinear()
    .domain(line.value.yBounds)
    .range([currentHeight - margin, 0])

  // Main SVG container
  svg = d3
    .select(`#lineChart-${cid}`)
    .append('svg')
    .attr('width', currentWidth + 'px')
    .attr('height', currentHeight + 'px')
    .attr('viewBox', [0, 0, currentWidth + margin * 2, currentHeight + margin * 2])
    .append('g')
    .attr('transform', `translate(${margin}, ${margin})`)
    .attr('style', 'max-width: 100%; height: auto; overflow: visible; font: 10px sans-serif;')

  // Clip path is used for highlighting areas
  /*   svg
    .append('clipPath')
    .attr('id', 'clipRect')
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', currentWidth - margin)
    .attr('height', currentHeight - margin) */

  // Axis
  const xAxis = d3
    .axisBottom(scaleX)
    .tickSize(currentHeight - margin)
    .tickSizeOuter(0)
    //.tickFormat(d3.timeFormat('%b'))
    .tickPadding(15)

  const yAxis = d3
    .axisLeft(scaleY)
    .tickSize(margin - currentWidth)
    .tickSizeOuter(0)
    .ticks(12)
    .tickPadding(20)

  // Add the X Axis
  svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(${margin}, 0)`)
    .attr('font-weight', '100')
    .attr('font-family', '"Roboto", "sans-serif"')
    .call(xAxis)

  // Add the Y Axis
  svg
    .append('g')
    .attr('class', 'y-axis')
    .attr('transform', `translate(${margin}, 0)`)
    .attr('font-weight', '100')
    .attr('font-family', '"Roboto", "sans-serif"')
    .call(yAxis)
    .append('text')
    .attr('y', 15)
    .attr('transform', 'rotate(-90)')

  // X Axis labels
  const textColor = darkmode.value ? 'white' : 'black'
  svg
    .append('text')
    .attr('transform', 'translate(' + currentWidth / 2 + ' ,' + (currentHeight + 10) + ')')
    .style('text-anchor', 'middle')
    .text(line.value.labels[0])
    .style('fill', textColor)
    .style('font-size', 16)

  // Y Axis labels
  svg
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 0 - 30)
    .attr('x', 0 - currentHeight / 2)
    .attr('dy', '1em')
    .style('text-anchor', 'middle')
    .text(line.value.labels[1])
    .style('fill', textColor)
    .style('font-size', 16)

  const lineGenerator = d3
    .line<LineData>()
    .x((d) => scaleX(d.x))
    .y((d) => scaleY(d.y))
    .curve(d3.curveCardinal)

  aLines = svg.append('g').attr('class', 'lines').attr('transform', `translate(${margin}, 0)`)

  // Data plot
  aLines
    .selectAll('line-group')
    .data([line.value])
    .enter()
    .append('g')
    .attr('class', 'line-group')
    .append('path')
    .attr('class', 'line')
    .attr('d', (d) => lineGenerator(d.data))
    //.attr('clip-path', 'url("#clipRect")')
    .style('fill', 'none')
    .style('stroke', line.value.colour)
    .style('opacity', 0.9)
    .style('stroke-width', 1)
    .on('mouseover', function () {
      d3.select(this).style('stroke-width', 4)
    })
    .on('mouseout', function () {
      d3.select(this).style('stroke-width', 2)
    })

  // Label plot
  aLines
    .selectAll('.labels')
    .data([line.value])
    .enter()
    .append('g')
    .append('text')
    .attr('clip-path', 'url("#clipRect")')
    .attr('x', function (d) {
      let hori = d.data[d.data.length - 1]['x']
      return scaleX(hori) + 5
    })
    .attr('y', function (d) {
      let vert = d.data[d.data.length - 1]['y']
      return scaleY(vert) + 5
    })
    .attr('font-size', '16px')
    .text(function (d) {
      return d.student
    })
    .style('fill', function () {
      return line.value.colour
    })
    .attr('opacity', 1)

  drawEventMarkers()
}

const drawValueLines = () => {
  aLines.selectAll('.valueLine').remove()
  // Value line
  aLines
    .append('line') // attach a line
    .attr('class', 'valueLine')
    .style('stroke', props.darkmode ? 'white' : 'black') // colour the line
    .attr('x1', scaleX(time.value)) // x position of the first end of the line
    .attr('y1', scaleY(line.value.yBounds[0])) // y position of the first end of the line
    .attr('x2', scaleX(time.value)) // x position of the second end of the line
    .attr('y2', scaleY(line.value.yBounds[1]))
}

const drawEventMarkers = () => {
  aLines.selectAll('.eventBox').remove()
  events.value.forEach((e) => {
    const w = scaleX(e.end - e.start || 1)
    // Hmm, I don't understand why '- 10' is needed here!
    const h = scaleY(line.value.yBounds[1] - line.value.yBounds[0] - 10)
    const x = scaleX(e.start)
    const y = scaleY(line.value.yBounds[1])
    // Event Marker Box
    aLines
      .append('rect')
      .attr('class', 'eventBox')
      .attr('width', w)
      .attr('height', h)
      .attr('x', x)
      .attr('y', y)
      .attr('fill', '#FFFACD')
      .attr('stroke-dasharray', [0, w, h, 0])
      .style('stroke', 'gold')

    /* aLines
      .append('text')
      .attr('x', x + 10)
      .attr('y', 10)
      .attr('dy', '.35em')
      .text(e.text) */
  })
}

watch(
  () => darkmode.value,
  () => setupChart()
)
watch(
  () => time.value,
  () => drawValueLines()
)
watch(
  () => events.value,
  () => drawEventMarkers()
)
</script>
