<template>
  <div class="flex flex-col">
    <div class="flex mt-3">
      <div v-for="(rating, index) in ratings" :key="index" class="flex items-center mr-4">
        <div class="w-4 h-4 rounded-full mr-2" :class="rating.color"></div>
        <p class="text-black dark:text-white">{{ rating.value }}</p>
      </div>
    </div>
    <div v-for="(id, nI) in nettskjemaIds" :key="nI" class="mt-11 mb-3">
      <table class="w-full max-w-screen-lg text-black dark:text-tl-grey-300">
        <thead>
          <tr class="border-none">
            <th class="float-left">Question</th>
            <th v-for="(user, index) in users" :key="index">Student {{ index + 1 }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(question, qI) in Object.keys(NETTSKJEMA_QUESTIONS)" :key="qI" class="border">
            <td class="p-3">{{ Object.values(NETTSKJEMA_QUESTIONS)[qI] }}</td>
            <td v-for="(user, uI) in users" :key="uI">
              <div
                class="ml-6 w-6 h-6 bg-tl-traffic-agree rounded-full"
                :class="questionColor(user, id, qI)"
              ></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

import { NETTSKJEMA_QUESTIONS } from '@/constants'
import { useSurveyStore } from '@/composition/stores/surveyStore.js'
import { useTeamStore } from '@/composition/stores/teamStore.js'

enum ExperienceLegend {
  CompletelyAgree = 'CompletelyAgree',
  Agree = 'Agree',
  Neutral = 'Neutral',
  Disagree = 'Disagree',
  CompletelyDisagree = 'CompletelyDisagree'
}

interface ExperienceRating {
  color: string
  value: ExperienceLegend
}

const ratings: ExperienceRating[] = [
  { color: 'bg-tl-traffic-cagree', value: ExperienceLegend.CompletelyAgree },
  { color: 'bg-tl-traffic-agree', value: ExperienceLegend.Agree },
  { color: 'bg-tl-traffic-neutral', value: ExperienceLegend.Neutral },
  { color: 'bg-tl-traffic-disagree', value: ExperienceLegend.Disagree },
  { color: 'bg-tl-traffic-cdisagree', value: ExperienceLegend.CompletelyDisagree }
]

const surveyStore = useSurveyStore()
const teamStore = useTeamStore()
const nettskjemaIds = surveyStore.getters.nettskjemaIds
const answers = surveyStore.getters.allAnswers

const users = computed(() => {
  const uniqueUserNames: string[] = []
  for (const answer of answers.value) {
    console.log(answer.groupId)
    if (!uniqueUserNames.includes(answer.userName)) uniqueUserNames.push(answer.userName)
  }
  return uniqueUserNames
})

onMounted(() => {
  surveyStore.actions.selectSurveyForm(teamStore.getters.selectedTeam.value)
})

const questionColor = (userName: string, id: number, index: number) => {
  const text = answers.value.filter(
    (a) => a.userName === userName && a.nettskjemaId === id.toString()
  )[index]?.chosenAnswers[0]
  // choose ' index ' of array to get the right question answer
  if (text?.includes('cAgree')) return 'bg-tl-traffic-agree'
  if (text?.includes('cCompletelyAgree')) return 'bg-tl-traffic-cagree'
  if (text?.includes('cNeitherAgreeNorDisagree')) return 'bg-tl-traffic-neutral'
  if (text?.includes('cDisagree')) return 'bg-tl-traffic-disagree'
  if (text?.includes('cCompletelyDisagree')) return 'bg-tl-traffic-cdisagree'
}
</script>
