<!-- Copyright 2020, 2021 Richard Nesnass, Sharanya Manivasagam and Ole Smørdal

 This file is part of VIVA.

 VIVA is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 VIVA is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with VIVA.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="flex flex-col justify-center items-center"
    @pointerup="() => (state.pauseUpdates = false)"
  >
    <div class="flex flex-col w-full relative rounded-20px">
      <div class="flex justify-center flex-grow relative h-full">
        <video
          crossorigin="anonymous"
          muted
          class="flex flex-grow rounded-20px rounded-b-none"
          :class="state.fullScreenMode ? 'playbackVideo' : 'playbackVideoSmall'"
          ref="videoElement"
          id="videoElement"
          oncontextmenu="return false;"
          playsinline
          webkit-playsinline
          preload="metadata"
          :onloadedmetadata="setPlayerHeight"
        >
          <track label="Norsk" kind="subtitles" srclang="no" :src="subtitles" default />
        </video>
        <audio ref="audioElement" id="audioElement"></audio>
      </div>

      <div class="bg-gray-100 flex flex-col w-full rounded-b-20px p-2">
        <div class="flex flex-col items-center">
          <div class="w-full flex flex-row items-center justify-center">
            <!-- Play / pause button -->
            <div class="flex justify-center items-center">
              <div
                v-show="!state.playing"
                class="flex items-center justify-center w-8 h-8 rounded-full p-2 pl-3 mr-2 border border-black"
                @click.stop="startPlaying()"
              >
                <img :src="playButtonSVG" alt="play-button" class="invert" />
              </div>
              <div
                v-show="state.playing"
                class="flex items-center justify-center w-8 h-8 rounded-full p-3 mr-2 border border-black"
                @click.stop="pausePlaying()"
              >
                <img :src="pauseButtonSVG" alt="pause-button" class="invert" />
              </div>
            </div>
            <!-- Position scrubber -->
            <div
              class="flex flex-grow px-6 items-center"
              @pointerdown="() => (state.pauseUpdates = true)"
              @pointerup="() => (state.pauseUpdates = false)"
            >
              <Slider
                class="w-full h-1 playing-slider"
                v-model="state.currentPlayerTime"
                :tooltips="false"
                :format="formatProgressTooltip"
                :max="scrubberMax"
                :min="scrubberMin"
                @change="adjustProgress"
              />
            </div>
            <div class="mr-2">
              <span>{{ secondsToTime(state.currentPlayerTime) }}</span>
            </div>
            <img
              :src="state.showControls ? cross : plus"
              alt="plus-button"
              class="w-4 invert cursor-pointer"
              @click="state.showControls = !state.showControls"
            />
          </div>
          <!-- Additional controls -->
          <div class="flex justify-around align-center w-full mt-7 mb-3" v-if="state.showControls">
            <div class="flex w-32 h-6 mt-2 flex-row items-center">
              <div class="flex flex-grow">
                <Slider
                  class="volume-slider h-2 w-full"
                  orientation="horizontal"
                  direction="ltr"
                  :format="formatVolumeTooltip"
                  :step="0.01"
                  :min="0"
                  :max="1"
                  v-model="state.currentVolume"
                  @change="adjustVolume"
                />
              </div>
              <div class="flex w-6 h-6 ml-4">
                <img v-if="state.currentVolume > 0" :src="soundOnButtonSVG" alt="volumeOn-button" />
                <img v-else :src="soundOffButtonSVG" alt="volumeOff-button" />
              </div>
            </div>

            <div class="flex w-6 h-6 mt-2" @click.stop="toggleScreenMode()">
              <img :src="fullscreenButtonSVG" alt="fullscreen-button" class="invert" />
            </div>
            <!--div class="flex w-32 h-6 mt-2 justify-center items-center">
              <input
                name="zoomvideo"
                type="checkbox"
                v-model="state.zoomVideo"
                @change="clickZoomVideo"
              />
              <label for="zoomvideo" class="ml-2">Timeline slice affects video</label>
            </div-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  type Ref,
  computed,
  onMounted,
  reactive,
  onUnmounted,
  watch,
  type PropType,
  toRefs
} from 'vue'
//import { useI18n } from 'vue-i18n'
import { formatTime, secondsToTime } from '@/utilities'

import playButtonSVG from '@/assets/icons/video/play.svg'
import pauseButtonSVG from '@/assets/icons/video/pause.svg'
import fullscreenButtonSVG from '@/assets/icons/video/scale_up.svg'
import soundOnButtonSVG from '@/assets/icons/video/sound_on.svg'
import soundOffButtonSVG from '@/assets/icons/video/sound_off.svg'
import plus from '@/assets/icons/svg/plus.svg'
import cross from '@/assets/icons/svg/cross.svg'
import type { TLEvent } from '@/models/nursing'

import Slider from '@vueform/slider'

import type { Segment } from '@database'

import { useSessionStore } from '@/composition/stores/sessionStore'
import { serverBaseUrl } from '@/constants'

//import Button from '@/components/base/Button.vue'
//import IconBase from '@/components/icons/IconBase.vue'
//import IconCross from '@/components/icons/IconCross.vue'

/* const messages = {
  no: {},
  en: {}
} */

const sessionStore = useSessionStore()

interface State {
  duration: number
  segments: Segment[]
  playerHeight: number
  highlightedSegmentIndex: number
  trim: number[]
  localStartTime: string
  localEndTime: string
  currentVolume: number
  currentPlayerTime: number

  fullScreenMode: boolean
  volumeMenu: boolean
  playing: boolean
  editingEndTime: boolean
  editingStartTime: boolean
  incorrectStartTime: boolean
  incorrectEndTime: boolean
  pauseUpdates: boolean
  openCard: boolean
  zoomVideo: boolean
  showControls: boolean
  selectedEvent?: TLEvent
}

// TODO: selectedVideo is a streamed buffer from the API...
const state: State = reactive({
  duration: 0,
  segments: [] as Segment[],
  playerHeight: 300,
  highlightedSegmentIndex: -1,
  trim: [0, 0],
  localStartTime: '',
  localEndTime: '',
  currentVolume: 0.5,
  currentPlayerTime: 0,

  fullScreenMode: false,
  volumeMenu: true,
  playing: false,
  editingEndTime: false,
  editingStartTime: false,
  incorrectStartTime: false,
  incorrectEndTime: false,
  pauseUpdates: false,
  openCard: false,
  zoomVideo: false,
  showControls: false,
  selectedEvent: undefined
})

const emit = defineEmits<{
  (e: 'timeupdate', time: number): void
  (e: 'trimupdate', trim: number[]): void
}>()

const props = defineProps({
  trim: {
    type: Object as PropType<number[]>,
    default: () => {
      return [0, 0]
    }
  }, // [0,0] start / end of trim selection
  videoIndex: { type: Number, default: 0 },
  group: { type: String, default: 'group1' },
  session: { type: Number, default: 1 }
})

const { trim } = toRefs(props)

//const { t } = useI18n({ messages })
const videoElement: Ref<HTMLVideoElement | undefined> = ref()
const audioElement: Ref<HTMLAudioElement | undefined> = ref()
const subtitles = ref('')

function adjustForSelectedEvent(event: TLEvent) {
  if (videoElement.value && audioElement.value && event) {
    state.selectedEvent = event
    videoElement.value.currentTime = event.start
    audioElement.value.currentTime = event.start
    state.currentPlayerTime = event.start
  }
}

onMounted(() => {
  const selectedEventOnLoad = sessionStore.getters.selectedEvent.value
  if (selectedEventOnLoad) adjustForSelectedEvent(selectedEventOnLoad)
})

watch(sessionStore.getters.selectedEvent, (event) => {
  if (event) adjustForSelectedEvent(event)
})

const formatVolumeTooltip = function (value: number) {
  return Math.floor(value * 100)
}
const formatProgressTooltip = function (value: number) {
  return formatTime(value, state.trim[0])
}

function setup() {
  loadPlayers()
  setPlayerHeight()
}

// Run setup on route change
onMounted(() => {
  setup()
  window.addEventListener('resize', setPlayerHeight)
})

onUnmounted(() => {
  window.removeEventListener('resize', setPlayerHeight)
})

watch(
  () => trim.value,
  () => {
    adjustTrim(trim.value)
  }
)

// Text to display UI for the current video time and total
/* const playerTime = computed(() => {
  const currentTime = formatTime(state.currentPlayerTime, state.trim[0])
  const totalTime = formatTime(state.trim[1], state.trim[0])
  return `${currentTime} / ${totalTime}`
}) */

const scrubberMax = computed(() => {
  const duration = state.duration || 0
  return state.trim[1] || duration
})

const scrubberMin = computed(() => {
  return state.trim[0] || 0
})

function setPlayerHeight() {
  setTimeout(() => {
    state.playerHeight = videoElement.value?.getBoundingClientRect().height || 300
  }, 600)
}

// Event listener for UI volume adjustment
function adjustVolume(level: number): void {
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (audioPlayer) {
    audioPlayer.volume = level
  }
}

async function adjustProgress(value: number): Promise<void> {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer) {
    videoPlayer.currentTime = value
    audioPlayer.currentTime = value
    if (!state.playing) {
      state.currentPlayerTime = value
      emit('timeupdate', value)
    }
    //audioPlayer.stop()
    //await audioPlayer.play(value)
  }
}

function toggleScreenMode(): void {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  if (videoPlayer && !state.fullScreenMode) videoPlayer.requestFullscreen()
  else document.exitFullscreen()
  // fullScreenMode.value = !fullScreenMode.value
}

function onTimeUpdate() {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  if (videoPlayer) {
    emit('timeupdate', videoPlayer.currentTime) // emit current time event
    if (
      videoPlayer.currentTime >= state.trim[1] ||
      (state.selectedEvent &&
        state.selectedEvent.end &&
        videoPlayer.currentTime >= state.selectedEvent.end)
    ) {
      stopPlaying()
    } else {
      if (!state.pauseUpdates) state.currentPlayerTime = videoPlayer.currentTime
    }
  }
}

async function startPlaying() {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer) {
    state.playing = true
    videoPlayer.addEventListener('timeupdate', onTimeUpdate)
    await videoPlayer.play()
    videoPlayer.currentTime = state.currentPlayerTime
    audioPlayer.play()
    audioPlayer.currentTime = state.currentPlayerTime
    audioPlayer.volume = state.currentVolume
  }
}

function stopPlaying() {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer) {
    videoPlayer.pause()
    audioPlayer.pause()
    state.playing = false
    videoPlayer.currentTime = state.trim[0]
    if (state.selectedEvent) videoPlayer.currentTime = state.selectedEvent.start
    audioPlayer.currentTime = state.trim[0]
    state.currentPlayerTime = videoPlayer.currentTime
    videoPlayer.removeEventListener('timeupdate', onTimeUpdate)
  }
}

function pausePlaying() {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer) {
    videoPlayer.pause()
    audioPlayer.pause()
    state.playing = false
    videoPlayer.removeEventListener('timeupdate', onTimeUpdate)
  }
}

// Event listener for UI trim adjustment
// Trim value is only emitted upon 'save'. See 'confirmTrim()'
async function adjustTrim(newValue: number[]): Promise<void> {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer && state.zoomVideo) {
    state.trim = newValue
    if (state.currentPlayerTime > newValue[1]) {
      videoPlayer.currentTime = newValue[1]
      audioPlayer.currentTime = newValue[1]
      state.currentPlayerTime = newValue[1]
    }
    if (state.currentPlayerTime < newValue[0]) {
      videoPlayer.currentTime = newValue[0]
      audioPlayer.currentTime = newValue[0]
      state.currentPlayerTime = newValue[0]
    }
  }
}

/* function clickZoomVideo() {
  if (!state.zoomVideo) {
    state.trim = [0, state.duration]
  }
} */

// Load the player with video data from a new result
// or reload it from store if reloadingFromStore == true
// reloadingFromStore is true / triggered after the video data has been decrypted
// or after returning to the video from the list
function loadPlayers(): void {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer) {
    const dataLoaded = () => {
      state.duration = videoElement.value?.duration || 0
      state.trim[1] = state.duration
      emit('trimupdate', state.trim)
      videoPlayer.removeEventListener('loadeddata', dataLoaded)
    }

    subtitles.value = `${serverBaseUrl}/api/file?group=${props.group}&session=${props.session}&module=Transcript&index=1`
    audioPlayer.src = `${serverBaseUrl}/api/file?group=${props.group}&session=${props.session}&module=CameraAudio&index=0`
    const videoPath = `${serverBaseUrl}/api/file?group=${props.group}&session=${props.session}&module=CameraVideo&index=${props.videoIndex}`

    videoPlayer.setAttribute('src', videoPath)
    videoPlayer.setAttribute('type', 'video/h264')
    videoPlayer.addEventListener('loadeddata', dataLoaded)
    videoPlayer.addEventListener('ended', stopPlaying, false)
    videoPlayer.addEventListener('error', (error) => {
      console.log(error)
    })

    videoPlayer.load()
    audioPlayer.load()
  }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
.volume-slider {
  --slider-handle-bg: #2a8805;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 8px;
  --slider-vertical-height: 4rem;
  --slider-bg: #444;
  --slider-connect-bg: #2a8805;
  --slider-tooltip-bg: #2a8805;
}
.volume-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}
/* .volume-slider ::v-deep(.slider-handle) {
  right: calc(
    var(--slider-handle-height, 16px) / 2 * -1 - var(--slider-height, 6px) / 2 * -1
  ) !important;
} */
.trimming-slider {
  --slider-handle-bg: #059fff;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 3px;
  --slider-vertical-height: 4rem;
  --slider-bg: rgba(163, 185, 255, 0.2);
  --slider-connect-bg: #059fff;
  --slider-tooltip-bg: #059fff;
  --slider-tooltip-font-size: 0.5rem;
  --slider-tooltip-font-weight: 200;
  --slider-tooltip-line-height: 0.3rem;
}
.trimming-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}

.playing-slider {
  --slider-handle-bg: #2a8805;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 3px;
  --slider-vertical-height: 4rem;
  --slider-bg: rgba(163, 185, 255, 0.2);
  --slider-connect-bg: #2a8805;
  --slider-tooltip-bg: #2a8805;
}
.playing-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}

.layout {
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.playbackVideo {
  margin: 0;
  width: 100%;
  background-color: green;
  z-index: 1;
}
.playbackVideoSmall {
  margin: auto;
  width: 80%;
  background-color: green;
  z-index: 1;
}
/* video::-webkit-media-controls-enclosure {
  display: none !important;
} */
#videoContainer {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

#segments {
  height: 50%;
}

.highlight-tooltip {
  background: var(--slider-tooltip-bg, #10b981);
  border: 1px solid var(--slider-tooltip-bg, #10b981);
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 10px);
  min-height: var(--slider-tooltip-min-height, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.highlight-tooltip span {
  display: none;
}

.highlight-tooltip:hover {
  background: var(--slider-tooltip-bg, #10b981);
  border: 1px solid var(--slider-tooltip-bg, #10b981);
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.highlight-tooltip:hover span {
  display: block;
}

.highlight-horizontal .highlight-tooltip-top {
  bottom: calc(
    var(--slider-handle-height, 16px) + var(--slider-tooltip-arrow-size, 5px) +
      var(--slider-tooltip-distance, 3px)
  );
  left: 50%;
  transform: translate(-50%);
}

.highlight-horizontal .highlight-tooltip-top:before {
  border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
  border-top-color: inherit;
  bottom: calc(var(--slider-tooltip-arrow-size, 5px) * -2);
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 0;
}
</style>
