<template>
  <div
    v-if="selectedTab"
    class="p-16 rounded-40px"
    :class="{
      'bg-tl-green-600 dark:bg-tl-green-100': itemColour === 'green',
      'bg-tl-lilla-600 dark:bg-tl-lilla-100': itemColour === 'lilla'
    }"
  >
    <div class="flex flex-row">
      <div class="flex flex-col w-2/3">
        <div
          class="flex flex-row text-sm"
          :class="{
            'text-black dark:text-tl-green-400': itemColour === 'green',
            'text-black dark:text-tl-lilla-400': itemColour === 'lilla'
          }"
        >
          {{ meetingText }}
        </div>
        <div class="flex flex-row text-black dark:text-white text-4xl font-medium mt-4">
          <span v-html="feedback[0]" />
        </div>
        <div class="flex flex-row mt-8">
          <div
            id="main-content"
            class="text-black text-opacity-50 dark:text-white overflow-hidden"
            :class="{
              'max-h-10': toggleText
            }"
          >
            <span>Topics : </span><span v-html="feedback[1]" />
          </div>
        </div>
        <!-- Read More/Hide Button -->
        <div class="flex flex-row mt-1">
          <div class="flex flex-col">
            <button
              id="toggle-btn"
              class="text-black text-opacity-50 dark:text-white font-semibold focus:outline-none underline"
              @click="toggleText = !toggleText"
            >
              {{ toggleText ? 'Read More' : 'Hide' }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-1/12"></div>
      <div class="flex flex-col w-3/12 text-black dark:text-white">
        <div class="flex flex-row">{{ createdDate }}</div>
        <div class="flex flex-row pb-5" v-if="meetingType == 'Online Meeting'">
          Participants ({{ durationChartData.length }})
        </div>
        <div class="flex flex-row my-4">
          <div
            class="px-2.5 py-1.5 font-semibold rounded-md text-black"
            :class="{
              'bg-tl-green-400': itemColour === 'green',
              'bg-tl-lilla-400': itemColour === 'lilla'
            }"
          >
            {{ meetingType }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-row mt-10 p-10 rounded-20px text-black dark:text-white"
      :class="{
        'bg-tl-green-500 dark:bg-tl-green-200': itemColour === 'green',
        'bg-tl-lilla-500 dark:bg-tl-lilla-200': itemColour === 'lilla'
      }"
    >
      <div class="flex flex-col w-screen">
        <div class="flex flex-row">
          <div
            class="px-5 py-2.5 border rounded-3xl text-2xl"
            :class="{
              'border-black dark:border-tl-green-400': itemColour === 'green',
              'border-black dark:border-tl-lilla-400': itemColour === 'lilla'
            }"
          >
            Action Items
          </div>
        </div>
        <div class="flex flex-row mt-4">
          <div
            id="action-item-content"
            class="text-lg overflow-hidden"
            :class="{ 'max-h-10': actionItemToggleText }"
          >
            <span v-html="feedback[2]" />
          </div>
        </div>
        <!-- Read More/Hide Button -->
        <div class="flex flex-row mt-5">
          <p
            id="toggle-btn"
            class="font-semibold"
            :class="{
              'border-black dark:text-tl-green-400': itemColour === 'green',
              'border-black dark:text-tl-lilla-400': itemColour === 'lilla',
              'cursor-pointer underline': actionItemToggleText
            }"
            @click="actionItemToggleText = !actionItemToggleText"
          >
            {{ actionItemToggleText ? 'Read More' : 'Hide' }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-row" v-if="meetingType == 'Online Meeting'">
      <div
        class="flex flex-col w-1/2 mt-10 p-10 mr-2 rounded-20px"
        :class="{
          'bg-tl-green-500 dark:bg-tl-green-200': itemColour === 'green',
          'bg-tl-lilla-500 dark:bg-tl-lilla-200': itemColour === 'lilla'
        }"
      >
        <div class="flex flex-row text-black dark:text-white">
          <div
            class="px-5 py-2.5 border rounded-3xl text-2xl relative"
            :class="{
              'border-black dark:border-tl-green-400': itemColour === 'green',
              'border-black dark:border-tl-lilla-400': itemColour === 'lilla'
            }"
          >
            <span>Speaking Time</span>
            <div
              class="rounded-full cursor-pointer absolute -top-2 -right-2 border-2 border-black dark:border-white bg-white dark:bg-black p-2 w-6 h-6 text-xs text-center font-bold flex items-center justify-center"
              @click="toggleInfoBox('speakingTime')"
            >
              i
            </div>
          </div>
          <div v-if="infoBoxVisible === 'speakingTime'" class="ml-4">
            Percentage of speaking time per person during the meeting
          </div>
        </div>
        <div class="flex flex-row mt-4 justify-center" id="durPieChart">
          <PieChart class="h-64" :data="durationChartData" :colours="legendColours" />
        </div>
      </div>
      <div
        class="flex flex-col w-1/2 mt-10 p-10 ml-2 rounded-20px"
        :class="{
          'bg-tl-green-500 dark:bg-tl-green-200': itemColour === 'green',
          'bg-tl-lilla-500 dark:bg-tl-lilla-200': itemColour === 'lilla'
        }"
      >
        <div class="flex flex-row text-black dark:text-white">
          <div
            class="px-5 py-2.5 border rounded-3xl text-2xl relative"
            :class="{
              'border-black dark:border-tl-green-400': itemColour === 'green',
              'border-black dark:border-tl-lilla-400': itemColour === 'lilla'
            }"
          >
            <span>Speaking Turn</span>
            <div
              class="rounded-full cursor-pointer absolute -top-2 -right-2 border-2 border-black dark:border-white bg-white dark:bg-black p-2 w-6 h-6 text-xs text-center font-bold flex items-center justify-center"
              @click="toggleInfoBox('speakingTurn')"
            >
              i
            </div>
          </div>
          <div v-if="infoBoxVisible === 'speakingTurn'" class="ml-4 text-black dark:text-white">
            Number of times each person have spoken during the meeting
          </div>
        </div>
        <div class="flex flex-row mt-4 justify-center" id="turnPieChart">
          <PieChart class="h-64" :data="turnsChartData" :colours="legendColours" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, type Ref, watch, toRefs, onMounted, computed } from 'vue'
import { useTeamStore } from '@/composition/stores/teamStore'
import { useMeetingStore } from '@/composition/stores/meetingStore'
import PieChart from '@/components/charts/PieChart.vue'
import type { PieData } from '@/components/charts/PieChart.vue'
import { STUDENT_COLOURS, promptSectionsMeetings } from '@/constants'

const teamStore = useTeamStore()
const meetingStore = useMeetingStore()
const durationChartData: Ref<PieData[]> = ref([])
const turnsChartData: Ref<PieData[]> = ref([])
const legendColours: Record<string, string> = {
  'Student 1': STUDENT_COLOURS['Student 1'],
  'Student 2': STUDENT_COLOURS['Student 2'],
  'Student 3': STUDENT_COLOURS['Student 3'],
  'Student 4': STUDENT_COLOURS['Student 4'],
  'Student 5': STUDENT_COLOURS['Student 5'],
  'Student 6': STUDENT_COLOURS['Student 6'],
  'Student 7': STUDENT_COLOURS['Student 6']
}

const toggleText = ref(true)
const actionItemToggleText = ref(true)
const props = defineProps<{ selectedTab: string }>()
const { selectedTab } = toRefs(props)
const meetingType = ref('')
const textColour = ref('')
const createdDate = ref('')
const infoBoxVisible = ref('')

const studentNameMap = {
  S1: 'Student 1',
  S2: 'Student 2',
  S3: 'Student 3',
  S4: 'Student 4',
  S5: 'Student 5',
  S6: 'Student 6',
  S7: 'Student 7'
}

function toggleInfoBox(type: string) {
  if (infoBoxVisible.value === type) {
    infoBoxVisible.value = ''
  } else {
    infoBoxVisible.value = type
  }
}

const feedback = computed(() => {
  const f: String[] = []
  const feedback = teamStore.getters.meetingFeedback.value
  feedback.forEach((item) => {
    if (item.filename === selectedTab.value) {
      for (var index in promptSectionsMeetings) {
        if (promptSectionsMeetings[index] == item.section) {
          f.push(item.filefeedback)
        }
      }
    }
  })
  return f
})

const itemColour = computed(() => {
  if (meetingType.value === 'Online Meeting') {
    return 'green'
  } else {
    return 'lilla'
  }
})

const meetingText = computed(() => {
  return selectedTab.value
    .slice(0, selectedTab.value.indexOf('GRP'))
    .replaceAll('_', ' ')
    .toUpperCase()
})

const displayMeeting = () => {
  const selectedMeeting = meetingStore.getters.speakingInfo.value.find(
    (item) => item.filename == selectedTab.value
  )

  createdDate.value = selectedMeeting?.createdDate ?? ''

  if (selectedMeeting?.type === 'online') {
    meetingType.value = 'Online Meeting'
    textColour.value = 'green'
    durationChartData.value = []
    turnsChartData.value = []
    selectedMeeting.speakinginfo.map((item) => {
      durationChartData.value.push({
        name: studentNameMap[item.speaker as keyof typeof studentNameMap],
        value: item.duration
      })
      turnsChartData.value.push({
        name: studentNameMap[item.speaker as keyof typeof studentNameMap],
        value: item.count
      })
    })
  } else {
    meetingType.value = 'Physical Meeting'
    textColour.value = 'tl-lilla'
  }
}

onMounted(() => displayMeeting())

watch(selectedTab, () => displayMeeting())
</script>
