import { type ComputedRef, computed, reactive } from 'vue'
import { type StoreResponse } from '@/models/main.js'
import useApiService from '@/api/apiRequest.js'
import type { User } from '@database'

const apiService = useApiService()

interface State {
  user: User | undefined
  isRegistered: boolean
}

const state: State = reactive({
  user: undefined,
  isRegistered: false
})

interface Actions {
  reset: () => void
  logout: () => Promise<void>
  getUserDetails: () => Promise<StoreResponse>
  isValidSession: () => Promise<boolean>
  test: () => Promise<StoreResponse>
}

interface Getters {
  user: ComputedRef<User | undefined>
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

function useUserStore(): ServiceInterface {
  const actions = {
    reset(): void {
      state.user = undefined
      state.isRegistered = false
      sessionStorage.removeItem('teamId') // remove item used to persist teamId
    },

    async getUserDetails(): Promise<StoreResponse> {
      if (state.user?._id) return { success: true }
      const res = await apiService.request.fetchUserDetails()
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.warn(res.errors ? res.errors : error)
        actions.reset() // reset user store to initial state
      } else {
        state.user = res.data
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    async isValidSession(): Promise<boolean> {
      const res = await actions.getUserDetails()
      if (!res.success) {
        this.reset() // reset user store to initial state
        return false
      }
      return true
    },

    async logout(): Promise<void> {
      await apiService.request.logout()
      this.reset() // reset values whenever logout is invoked
    },

    async test(): Promise<StoreResponse> {
      const res = await apiService.request.test()
      console.log(res)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
        actions.reset() // reset user store to initial state
      } else {
        console.log(res.data)
        return { success: true }
      }
      return { success: false, error: res.errors }
    }
  }

  const getters = {
    get user(): ComputedRef<User | undefined> {
      return computed(() => state.user)
    }
  }

  return { state, actions, getters }
}

export { useUserStore }
