<!-- Copyright 2020, 2021 Richard Nesnass, Sharanya Manivasagam and Ole Smørdal

 This file is part of VIVA.

 VIVA is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 VIVA is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with VIVA.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="flex flex-col justify-center items-center bg-tl-green-100 rounded-20px p-10 pb-20 pt-14"
  >
    <div class="flex flex-col w-full">
      <div class="pt-4 pb-6 flex flex-col px-6 items-center">
        <div class="w-full h-8 relative border-white border-2 rounded-2xl">
          <div class="absolute ml-4">
            <span class="text-white">Handover</span>
          </div>
          <div
            v-for="e in keyEvents"
            :key="e.id"
            :style="calculateEventLocation(e)"
            class="absolute w-24 h-16 odd:h-10 -top-10 odd:-top-4"
          >
            <div
              v-if="e.name !== 'Calling Start' && e.name !== 'Calling End'"
              class="relative h-full border-l-2 border-yellow-300 border-dotted"
            >
              <div class="absolute -top-6 -left-1">
                <span class="text-yellow-300 font-bold text-xs w-32"> {{ e.name }}</span>
              </div>
              <div class="absolute -bottom-7 -left-1">
                <span class="text-yellow-300 font-bold text-xs"> {{ secondsToTime(e.start) }}</span>
              </div>
            </div>
            <div v-if="e.name === 'Handover End'" class="absolute top-0 left-0 ml-4 mt-10">
              <span class="text-white">Stabilisation</span>
            </div>
            <div
              v-else-if="e.name === 'Patient Crisis'"
              class="absolute top-0 left-0 ml-4 mt-4 w-36"
            >
              <span class="text-white">Post-deterioration</span>
            </div>
          </div>
          <div
            v-for="e in visibleEvents"
            :key="e.id"
            class="absolute highlight-horizontal top-24 odd:top-16"
            :style="calculateEventLocation(e)"
          >
            <div class="relative">
              <div class="w-20 bg-slate-700 rounded-2xl text-center flex flex-row">
                <div class="w-2 mr-2"></div>
                <!--div
                  class="w-2 rounded-l-2xl mr-2"
                  :class="[e.status === EventStatus.True ? 'bg-green-500' : 'bg-red-500']"
                ></div-->
                <p class="cursor-pointer text-white" @click="selectEvent(e)">{{ e.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
//import { useI18n } from 'vue-i18n'
import type { TLEvent } from '@/models/nursing'
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import { secondsToTime } from '@/utilities'
import { Speaker } from '@/constants'
import { computed } from 'vue'

const { actions: sessionActions, getters: sessionGetters } = useSessionStore()
const speaker = sessionGetters.selectedSpeaker
const events = sessionGetters.events
const keyEvents = sessionGetters.keyEvents

const visibleEvents = computed(() =>
  events.value.filter((e) => e.owner === speaker.value || speaker.value == Speaker.All)
)

function selectEvent(e: TLEvent) {
  sessionActions.setSelectedEvent(e)
}

function calculateEventLocation(e: TLEvent) {
  // X
  const duration = sessionGetters.selectedSession.value?.duration ?? 1
  const x = (e.start / duration) * 100
  return `left: ${x}%;`
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
.volume-slider {
  --slider-handle-bg: #2a8805;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 8px;
  --slider-vertical-height: 4rem;
  --slider-bg: #444;
  --slider-connect-bg: #2a8805;
  --slider-tooltip-bg: #2a8805;
}
.volume-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}
/* .volume-slider ::v-deep(.slider-handle) {
  right: calc(
    var(--slider-handle-height, 16px) / 2 * -1 - var(--slider-height, 6px) / 2 * -1
  ) !important;
} */
.trimming-slider {
  --slider-handle-bg: #059fff;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 3px;
  --slider-vertical-height: 4rem;
  --slider-bg: rgba(163, 185, 255, 0.2);
  --slider-connect-bg: #059fff;
  --slider-tooltip-bg: #059fff;
  --slider-tooltip-font-size: 0.7rem;
  --slider-tooltip-font-weight: 200;
  --slider-tooltip-line-height: 0.5rem;
}
.trimming-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}

.playing-slider {
  --slider-handle-bg: #2a8805;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 3px;
  --slider-vertical-height: 4rem;
  --slider-bg: rgba(163, 185, 255, 0.2);
  --slider-connect-bg: #2a8805;
  --slider-tooltip-bg: #2a8805;
}
.playing-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}

.layout {
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.playbackVideo {
  margin: 0;
  width: 100%;
  background-color: green;
  z-index: 1;
}
.playbackVideoSmall {
  margin: auto;
  width: 80%;
  background-color: green;
  z-index: 1;
}
/* video::-webkit-media-controls-enclosure {
  display: none !important;
} */
#videoContainer {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

#segments {
  height: 50%;
}
</style>
