<template>
  <div :id="'pieContainer-' + cid" class="m-2 flex flex-row items-center">
    <div :id="'pieChart-' + cid"></div>
    <div :id="'legend' + cid" class="ml-7">
      <div
        v-for="entry in legend"
        :key="entry.name"
        class="flex flex-row items-center text-white text-sm font-normal mb-4 last:mb-0"
      >
        <!-- entry.colour can be a tailwind class or #colour value -->
        <div
          class="w-2.5 h-2.5 rounded-full mr-1.5"
          :class="entry.colour"
          :style="{ 'background-color': entry.colour }"
        ></div>
        <p class="text-black dark:text-white">{{ entry.name }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useId, computed, onMounted, onUnmounted, toRefs, type PropType, watch } from 'vue'
import * as d3 from 'd3'

export interface PieData {
  name: string
  value: number
}

const props = defineProps({
  data: { type: Object as PropType<PieData[]>, required: true },
  colours: { type: Object as PropType<Record<string, string>>, required: true }
})

const { data, colours } = toRefs(props)
const cid = useId()
const colour = (d: PieData) => colours.value[d.name] ?? '#AABBCC'

const legend = computed(() =>
  data.value.map((entry) => ({ name: entry.name, colour: colour(entry) }))
)

onMounted(() => {
  if (data.value.length) setupChart()
  window.addEventListener('resize', setupChart)
})

onUnmounted(() => {
  window.addEventListener('resize', setupChart)
})

watch(data, () => {
  setupChart()
})

function setupChart() {
  d3.select(`#pieChart-${cid}`).select('svg').remove()

  const container = d3.select(`#pieContainer-${cid}`)
  const currentHeight = parseInt(container.style('height'), 10)
  const currentWidth = currentHeight
  const margin = currentHeight * 0.05
  const radius = Math.min(currentWidth, currentHeight) / 2 - margin
  let valueSum = 0

  const svg = d3
    .select(`#pieChart-${cid}`)
    .append('svg')
    .attr('width', currentWidth)
    .attr('height', currentWidth)
    .attr('viewBox', [0, 0, currentWidth, currentWidth])
    .append('g')
    .attr('transform', 'translate(' + currentWidth / 2 + ',' + currentWidth / 2 + ')')
    .attr('style', 'max-width: 100%; height: auto; overflow: visible; font: 10px sans-serif;')

  const pie = d3.pie<PieData>().value((d: PieData) => d.value)
  const arc = d3.arc<d3.PieArcDatum<PieData>>().innerRadius(0).outerRadius(radius)
  const parsedData = pie(data.value)

  data.value.forEach((d) => (valueSum += d.value))

  // Compute the position of each group on the pie:

  var g = svg.selectAll('.arc').data(parsedData).enter().append('g').attr('class', 'arc')

  g.append('path')
    .attr('d', arc)
    .style('fill', (d) => colour(d.data))

  g.append('text')
    .attr('transform', function (d) {
      return 'translate(' + arc.centroid(d) + ')'
    })
    .attr('dy', '.35em')
    .style('text-anchor', 'middle')
    .text(function (d) {
      return Math.round((d.data.value * 100) / valueSum) + '%'
    })

  /*  const arcs = svg
    .datum(data.value)
    .selectAll('path')
    .data(parsedData)
    .join('path')
    .attr('fill', (d) => colour(d.data))
    .attr('d', arc) */
}
</script>
