/*
 Designed and developed by Engagelab
 */

import { Schema, Types, model } from 'mongoose'

export enum UserRole {
  user = 'user',
  monitor = 'monitor',
  admin = 'admin',
  logs = 'logs'
}

const presetUserRoles = 'user,monitor,admin,logs'
const userRolesAsArray: UserRole[] = presetUserRoles.split(',').map((r) => r as UserRole)

// Constants used both front and back end should be checked for consistency like this:
userRolesAsArray.forEach((ur) => {
  if (!UserRole[ur]) console.error('constants.js > userRoles mismatch with env file')
})

export interface Avatar {
  fileKey: string
  name: string
  ref: string
}

export interface UserData {
  _id: string
  status: {
    lastLogin: string
    browserLanguage: string
    canEditPlayers: boolean // User can make changes to Players (add, edit, remove)
    canEditGames: boolean // User can make changes to Games
    canSelectGames: boolean // User can see the 'game selection' view
    otcCreated: string
    appVersion: string
  }
  profile: {
    username: string
    password: string
    fullName: string
    provider: string // Dataporten or Canvas etc.?
    oauthID: string
    loginID: string
    ltiID: string
    email: string
    mobil: string
    language: string
    role: UserRole
  }
  tokens: {
    access_token: string
    id_token: string
    csrf_token: string
    local_token: string
    otc: string
  }
}

export interface User {
  _id: Types.ObjectId
  status: {
    lastLogin: Date
    browserLanguage: string
    otcCreated: Date
    appVersion: string
  }
  profile: {
    username: string
    password: string
    fullName: string
    provider: string // Dataporten or Canvas etc.?
    oauthID: string
    loginID: string // <-- This is used to match Dataporten with Canvas
    ltiID: string
    email: string
    mobil: string
    language: string
    role: UserRole
  }
  tokens: {
    access_token?: string
    id_token?: string
    csrf_token?: string
    local_token?: string
    otc?: string
  }

  redacted: () => Record<string, unknown>
}

const userSchema = new Schema<User>(
  {
    status: {
      lastLogin: { type: Schema.Types.Date, default: Date.now },
      browserLanguage: { type: String, default: 'no' }, // 'no', 'nb_NO', 'nn_NO', 'en'
      otcCreated: { type: Date }, // One-time-code creation stamp,
      appVersion: { type: String, default: '' }
    },
    profile: {
      username: { type: String, default: '' },
      password: { type: String, default: '' }, // Store in hashed form using bcrypt
      fullName: { type: String, default: '' },
      provider: { type: String, default: 'unknown' }, // Dataporten or Canvas etc.?
      oauthID: { type: String, default: '' },
      loginID: { type: String, default: '' },
      ltiID: { type: String, default: '' },
      email: { type: String, default: '' },
      mobil: { type: String, default: '' },
      language: { type: String, default: 'system' }, // Use a two letter code as the browser does, e.g 'no', 'en', 'sv', or 'system' for a user's system/browser default
      role: {
        type: String,
        enum: userRolesAsArray,
        default: userRolesAsArray[0]
      },
      ageGroup: { type: String },
      languagesSpoken: [{ type: String, default: [] }]
    },
    tokens: {
      access_token: { type: String },
      id_token: { type: String },
      csrf_token: { type: String },
      local_token: { type: String },
      otc: { type: String }
    }
  },
  { timestamps: true }
)

// Choose what attributes will be returned with the user object
// ** These attributes should be matched in the front end model **
userSchema.methods.redacted = function () {
  return {
    _id: this._id,
    status: this.status,
    profile: {
      username: this.profile.username,
      fullName: this.profile.fullName,
      email: this.profile.email,
      mobil: this.profile.mobil,
      language: this.profile.language,
      role: this.profile.role
    }
  }
}

// Duplicate the ID field.
// eslint-disable-next-line
userSchema.virtual('id').get(function (this: { _id: Schema.Types.ObjectId }) {
  // eslint-disable-next-line
  return this._id.toString()
})

// Ensure virtual fields are serialised.
userSchema.set('toJSON', {
  getters: true,
  virtuals: true
})
userSchema.set('toObject', {
  getters: true,
  virtuals: true
})

export const UserModel = model<User>('User', userSchema)
