<template>
  <div :id="'networkContainer-' + cid" class="m-2 flex flex-row items-center">
    <div :id="'networkChart-' + cid"></div>
    <div :id="'legend-' + cid" class="ml-7" v-if="showKey">
      <div
        v-for="entry in actors"
        :key="entry.id"
        class="flex flex-row items-center text-white text-sm font-normal mb-4 last:mb-0"
      >
        <div
          class="w-2.5 h-2.5 rounded-full mr-1.5"
          :style="{ 'background-color': entry.colour }"
        ></div>
        <p>{{ entry.label }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useId, onMounted, onUnmounted, toRefs, type PropType, nextTick, watch } from 'vue'
import * as d3 from 'd3'
import type { SimulationNodeDatum } from 'd3'

export interface ActorData extends SimulationNodeDatum {
  id: string
  label: string
  colour: string
}

export interface LinkData {
  source: string
  target: string
  label: string
}

interface EdgeEntry {
  source: ActorData
  target: ActorData
  label: string
  index: number // Used for cases where the same source and target exists more than once, to prevent link overlap
}

const props = defineProps({
  actors: { type: Object as PropType<ActorData[]>, required: true },
  links: { type: Object as PropType<LinkData[]>, required: true },
  showKey: { type: Boolean, default: true }
})

const { actors, links } = toRefs(props)
const cid = useId()

watch(props, () => {
  setupChart() // update on e.g. click on event in timeline
})

onMounted(() => {
  window.addEventListener('resize', setupChart)
  nextTick(() => {
    setupChart() // await page / video being renered to ensure correct positioning
  })
})

onUnmounted(() => {
  window.addEventListener('resize', setupChart)
})

function linkArc(d: EdgeEntry): string {
  const dx = d.target.x && d.source.x ? d.target.x - d.source.x : 0,
    dy = d.target.y && d.source.y ? d.target.y - d.source.y : 0
  let dr = Math.sqrt(dx * dx + dy * dy)
  if (d.index > 0) dr = dr / (1 + (1 / 2) * (d.index * 2 - 1))
  /* return (
    'M' +
    d.source.x +
    ',' +
    d.source.y +
    'A' +
    dr +
    ',' +
    dr +
    ' 0 0,1 ' +
    d.target.x +
    ',' +
    d.target.y
  ) */
  return (
    'M' +
    d.source.x +
    ',' +
    d.source.y +
    'A' +
    dr +
    ',' +
    dr +
    ' 0 0 1,' +
    d.target.x +
    ',' +
    d.target.y
  )
}

function setupChart() {
  d3.select(`#networkChart-${cid}`).select('svg').remove()

  const currentHeight = parseInt(d3.select(`#networkContainer-${cid}`).style('height'), 10)
  const currentWidth = currentHeight
  const circleRadius = 16
  const circleDistance = 250 - actors.value.length * 30

  const svg = d3.select(`#networkChart-${cid}`).append('svg')

  // Create A <g> tag on the <svg> used to contain the actual chart
  const graph = svg
    .attr('width', currentWidth)
    .attr('height', currentWidth)
    .attr('viewBox', [0, 0, currentWidth, currentWidth])
    .append('g')
    //.attr('transform', 'translate(' + currentWidth / 2 + ',' + currentWidth / 2 + ')')
    .attr('style', 'max-width: 100%; height: auto; overflow: visible; font: 10px sans-serif;')

  // Create a <def> element in the <svg> that is used to define arrow heads
  svg
    .append('defs')
    .append('marker')
    .attr('id', 'arrowhead')
    .attr('viewBox', '0 -5 10 10')
    .attr('refX', 0)
    .attr('refY', 0)
    .attr('markerWidth', 8)
    .attr('markerHeight', 8)
    .attr('orient', 'auto-start-reverse')
    .append('path')
    .attr('d', 'M0,-5L10,0L0,5')
    .attr('fill', 'white')

  const nodeHash: Record<string, ActorData> = {}
  const edges: EdgeEntry[] = []

  // Convert our data to be suitable for drawing circles and paths
  actors.value.forEach(function (actor) {
    if (!nodeHash[actor.id]) {
      nodeHash[actor.id] = actor
    }
  })
  links.value.forEach(function (link) {
    const index =
      (edges.find((e) => e.source.id == link.source && e.target.id == link.target)?.index ?? -1) + 1
    edges.push({
      source: nodeHash[link.source],
      target: nodeHash[link.target],
      label: link.label,
      index
    })
  })

  // On each 'tick' this redraws paths and circles
  // Append geometric shapes inside the <g> tag
  // It also ensures the arrow heads are pulled back from the circle center
  function updateNetwork() {
    const paths = graph.selectAll('path').data(edges)
    const text = graph.selectAll('.linkText').data(edges)
    const circles = graph.selectAll('circle').data(actors.value)

    paths
      .join('path')
      .style('fill', 'none')
      .style('stroke', '#FFF')
      .attr('d', function (d) {
        return linkArc(d)
      })
      .attr('class', 'link')
      .attr('id', function (d, i) {
        return `link-${cid}_` + i
      })
      .attr('marker-end', 'url(#arrowhead)')

    text
      .enter()
      .append('text')
      .style('stroke', '#FFFF00')
      .attr('class', 'linkText')
      .attr('x', 10) //Move the text from the start angle of the arc
      .attr('dx', 20) //Move the text from the start angle of the arc
      .attr('dy', -11)
      .append('textPath')
      .attr('xlink:href', function (d, i) {
        return `#link-${cid}_` + i
      })
      .text(function (d) {
        return d.label
      })

    // recalculate and back off the distance
    paths.attr('d', function (d) {
      const el: SVGGeometryElement = this as SVGGeometryElement
      const length = el.getTotalLength()
      // length of current path
      const pl = length
      // radius of circle plus backoff
      const r = circleRadius + 12 //<-- 12 is your radius 30 is the "back-off" distance
      // position close to where path intercepts circle
      const m = el.getPointAtLength(pl - r)

      const dx = m.x - (d.source.x ?? 0),
        dy = m.y - (d.source.y ?? 0)
      let dr = Math.sqrt(dx * dx + dy * dy)
      if (d.index > 0) dr = dr / (1 + 0.3 * (d.index * 3))

      //return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + dr + ' 0 0,1 ' + m.x + ',' + m.y
      return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + dr + ' 0 0 1,' + m.x + ',' + m.y
    })

    circles
      .join('circle')
      .attr('r', circleRadius)
      .style('fill', function (d) {
        return d.colour
      })
      .attr('cx', function (d) {
        return d.x || 0
      })
      .attr('cy', function (d) {
        return d.y || 0
      })
  }

  d3.forceSimulation(actors.value)
    .force('charge', d3.forceManyBody().strength(-100))
    .force('center', d3.forceCenter(currentWidth / 2, currentHeight / 2))
    .force('link', d3.forceLink().links(edges).distance(circleDistance))
    .on('tick', updateNetwork)
}
</script>

<style>
path.link {
  fill: red;
  stroke: #666;
  stroke-width: 1.5px;
}

.linkText {
  font-size: 1em;
}

text {
  fill: #000;
  font: 10px sans-serif;
  pointer-events: none;
}
</style>
