<template>
  <div class="flex flex-col b-white dark:bg-tl-grey-100 p-10 pt-0">
    <!-- Tabs -->
    <div class="w-full flex flex-row p-2 my-4">
      <div
        v-for="tab of tabs"
        :key="tab.routeName"
        class="rounded-full p-2.5 px-3.5 mr-2 last:mr-0 text-sm font-semibold cursor-pointer border border-black"
        :class="activeClass(tab.routeName)"
        @click="routeTo(tab.routeName)"
      >
        {{ tab.label }}
      </div>
    </div>
    <!-- Tab Content -->
    <router-view :time="state.currentTime" :trim="state.currentTrim" v-slot="{ Component }">
      <component :is="Component">
        <template v-slot:header>
          <div class="mb-4 text-white">
            <p v-if="selectedTab === TabName.TaskCoordination">
              Highlighted events from the simulation are shown below. Coloured circles indicate
              active roles and arrows represent task coordination between sender and receiver. Click
              an event for details.
            </p>
            <p v-if="selectedTab === TabName.Communication">
              Highlighted events from the simulation are shown below. Coloured circles indicate
              active roles and arrows represent closed loop communication between sender and
              receiver. Click an event for details.
            </p>
          </div>
        </template>
      </component>
    </router-view>
    <!-- Timeline -->
    <TimelineModule
      class="mt-2"
      :initialTime="state.currentTime"
      :initialTrim="state.initialTrim"
      :selectedTab="selectedTab"
      @trimupdate="(trim: number[]) => (state.currentTrim = trim)"
    ></TimelineModule>
  </div>
</template>

<script setup lang="ts">
import router from '@/router'
import TimelineModule from '@/components/nursing/TimelineModule.vue'
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import { reactive, ref } from 'vue'
import { Speaker, TabName } from '@/constants'

const tabs = [
  {
    label: 'Home',
    routeName: TabName.General
  },
  {
    label: 'Communication',
    routeName: TabName.Communication
  },
  {
    label: 'Task Coordination',
    routeName: TabName.TaskCoordination
  },
  {
    label: 'Stress Management',
    routeName: TabName.StressManagement
  }
]

interface State {
  currentTime: number
  currentTrim: number[]
  initialTime: number
  initialTrim: number[]
}

const state: State = reactive({
  currentTime: 0,
  currentTrim: [0, 0],
  initialTime: 0,
  initialTrim: [0, 0]
})

const { actions: sessionActions } = useSessionStore()
const selectedTab = ref(TabName.General)

function routeTo(tab: TabName) {
  selectedTab.value = tab
  sessionActions.setTab(tab)
  if (tab === TabName.TaskCoordination || tab === TabName.Communication) {
    sessionActions.setSpeaker(Speaker.All)
  } else sessionActions.setSpeaker(Speaker.None)
  router.push({ name: tab })
}

routeTo(TabName.General)

const activeClass = (tabName: string) =>
  selectedTab.value === tabName ? 'bg-white text-tl-grey-100' : 'bg-tl-grey-100 text-white'
</script>
