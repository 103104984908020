<template>
  <div class="flex flex-col pt-4 bg-white dark:bg-tl-grey-100 h-auto" @click="clearMenus()">
    <div v-if="router.currentRoute.value.name != 'Home'" class="mx-3 flex flex-row items-center">
      <div
        class="text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200 w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
        @click="navigateToCaseDashboard()"
      >
        <img class="w-3 rotate-90 dark:invert" :src="ArrowIcon" />
      </div>
      <p class="text-xl text-black dark:text-white ml-2">Dashboard</p>
    </div>
    <div class="m-10">
      <div class="flex mt-2 mb-12 mx-12">
        <div class="flex mr-1.5">
          <div
            class="flex px-4 py-3 rounded-xl cursor-pointer text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200"
          >
            <img
              v-if="teams.length > 1"
              :class="[showTeamsDropdown ? 'rotate-180' : '']"
              class="w-4 dark:invert mr-3"
              :src="ArrowIcon"
            />
            <p class="text-black dark:text-white text-4xl" @click.stop="toggleTeamDropdown()">
              {{ teamStore.getters.selectedTeam.value || 'Demo Team' }}
            </p>
            <div
              class="absolute -ml-4 -mt-3 z-50 text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200 rounded-xl text-xl"
              v-if="showTeamsDropdown"
            >
              <div
                class="cursor-pointer px-4 py-3"
                v-for="(team, index) in teams"
                :key="index"
                @click="selectTeam(team)"
              >
                <p class="text-4xl">{{ team }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div
            class="flex text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200 px-4 py-3 rounded-xl cursor-pointer"
          >
            <img
              v-if="true"
              :class="[showTypesDropdown ? 'rotate-180' : '']"
              class="w-4 dark:invert mr-3"
              :src="ArrowIcon"
            />
            <p class="text-4xl" @click.stop="toggleTypeDropdown()">
              {{ analyticsTypeText }}
            </p>
            <div class="absolute -ml-4 -mt-3 z-50 rounded-xl text-xl" v-if="showTypesDropdown">
              <div
                class="bg-tl-grey-900 dark:bg-tl-grey-200 cursor-pointer hover:font-bold hover:bg-tl-grey-300 dark:hover:bg-tl-grey-300 px-8 py-3"
                v-for="(at, index) in analyticsTypes"
                :key="index"
                @click="selectAnalytics(at.type)"
              >
                <p class="text-4xl">{{ at.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="meetingTabs" v-if="meetingTabs.length" class="w-full flex flex-row mx-12 mt-4 mb-6">
        <div
          v-for="mTab of meetingTabs"
          :key="mTab.id"
          class="rounded-full p-2.5 px-3.5 mr-2 last:mr-0 text-sm uppercase font-semibold cursor-pointer text-center"
          :class="{
            'bg-white text-black border border-black': mTab.id === selectedTab,
            ' bg-black text-white': mTab.id !== selectedTab,
            'bg-tl-lilla-300': mTab.type === 'physical' && mTab.id !== selectedTab,
            'bg-tl-green-200': mTab.type === 'online' && mTab.id !== selectedTab
          }"
          @click="selectTab(mTab.id)"
        >
          {{ mTab.name + mTab.date }}
        </div>
      </div>
      <!-- Tab Content -->
      <router-view :selected-tab="selectedTab" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import { useTeamStore } from '@/composition/stores/teamStore'
import ArrowIcon from '@/assets/icons/fontawesome/arrow-down-regular.svg'
import { AnalyticsType, PROMPTSECTION, promptSectionsMeetings } from '@/constants'
import { PROMPTYPE } from '@/constants'
import { useMeetingStore } from '@/composition/stores/meetingStore'
import { useSurveyStore } from '@/composition/stores/surveyStore'

interface MeetingTab {
  id: string
  type: string
  name: string
  date: string
}

const teamStore = useTeamStore()
const meetingStore = useMeetingStore()
const surveyStore = useSurveyStore()
const router = useRouter()

const teams = teamStore.getters.allTeams
const meetings = meetingStore.getters.speakingInfo
const nettskjemaIds = surveyStore.getters.nettskjemaIds

const analyticsTypes = [
  { type: AnalyticsType.MeetingAnalytics, name: 'Meeting Analytics' },
  { type: AnalyticsType.CollaborationExperience, name: 'Collaboration Experience' },
  { type: AnalyticsType.WorkDocuments, name: 'Work Documents' }
]

function navigateToCaseDashboard() {
  if (router.currentRoute.value.fullPath.includes('legal')) router.push('/legal/dashboard')
  else router.push('/nursing/groups')
}

const analyticsTypeText = computed(() => {
  switch (selectedAnalytics.value) {
    case AnalyticsType.MeetingAnalytics:
      return 'Meeting Analytics'
    case AnalyticsType.CollaborationExperience:
      return 'Collaboration Experience'
    case AnalyticsType.WorkDocuments:
      return 'Work Documents'
    default:
      return 'Meeting Analytics'
  }
})

function clearMenus() {
  showTeamsDropdown.value = false
  showTypesDropdown.value = false
}

const meetingTabs = computed(() => {
  let list: MeetingTab[] = []
  const routeName = router.currentRoute.value.name
  if (routeName === 'CollaborationExperience') {
    list = surveyStore.getters.nettskjemaIds.value.map((v, i) => ({
      id: v.toString(),
      type: 'none',
      name: `Survey on Collaboration ${i}`,
      date: ''
    }))
  } else if (routeName === 'MeetingAnalytics') {
    list = meetings.value.map((m) => ({
      id: m.filename,
      type: m.type,
      name: m.filename.slice(0, m.filename.indexOf('GRP')).replaceAll('_', ' ') + '- ',
      date: new Date(m.createdDate).toLocaleDateString('default', {
        month: 'long',
        year: 'numeric'
      })
    }))
  } else list = []
  return list.sort((a, b) => a.name.localeCompare(b.name))
})

const selectedAnalytics: Ref<AnalyticsType> = ref(AnalyticsType.MeetingAnalytics)
const showTeamsDropdown: Ref<boolean> = ref(false)
const showTypesDropdown: Ref<boolean> = ref(false)
const selectedTab: Ref<string> = ref('')

const onAnalyticsChange = (toTab: AnalyticsType) => {
  switch (toTab) {
    case AnalyticsType.MeetingAnalytics:
      selectedAnalytics.value = AnalyticsType.MeetingAnalytics
      if (meetingTabs.value.length > 0) {
        selectTab(meetingTabs.value[0].id)
      }
      break
    case AnalyticsType.WorkDocuments:
      selectedAnalytics.value = AnalyticsType.WorkDocuments
      break
    default:
      selectedAnalytics.value = AnalyticsType.CollaborationExperience
      if (nettskjemaIds.value && nettskjemaIds.value.length > 0) {
        selectTab(nettskjemaIds.value[0].toString())
      }
  }
}

async function fetchData() {
  // In the below code snippet, we loop through all prompt sections that are stored in squidex.
  // In the previous version of Legaltech,for all sections or parts for meeting analytics (the title, topic, action items) and work documents (feedback & summary),
  // ChatGPT feedback was generated in a single query to the OpenAI API.
  // For this new release of LegalTech module as part of TeamLearn, we generate the ChatGPT feedbacks for each different section separately
  // This means that a query is sent to OpenAI
  teamStore.actions.clearMeetingData()
  for (const section of Object.keys(PROMPTSECTION)) {
    const sectionValue = PROMPTSECTION[section as keyof typeof PROMPTSECTION]
    if (promptSectionsMeetings.includes(sectionValue)) {
      console.log('Section: ', sectionValue)
      await teamStore.actions.getDocumentFeedback(PROMPTYPE.Meeting, sectionValue)
    } else {
      await teamStore.actions.getDocumentFeedback(PROMPTYPE.Document, sectionValue)
    }
  }
  await teamStore.actions.getDocumentMetadata()
  await meetingStore.actions.getMeetingData()
}

onMounted(async () => {
  selectTeam('') // restore default values from sessionStorage if reloaded
  await fetchData()
  const routeName = router.currentRoute.value.name
  if (routeName) onAnalyticsChange(routeName as AnalyticsType)
})

function selectTab(id: string) {
  selectedTab.value = id
}

function toggleTeamDropdown() {
  const teacher = true
  if (teacher) {
    showTeamsDropdown.value = !showTeamsDropdown.value
  }
}

function toggleTypeDropdown() {
  showTypesDropdown.value = !showTypesDropdown.value
}

async function selectTeam(teamId: string) {
  teamStore.actions.selectTeam(teamId)
  showTeamsDropdown.value = false
  await fetchData()
  const routeName = router.currentRoute.value.name
  if (routeName) onAnalyticsChange(routeName as AnalyticsType)
}

function selectAnalytics(type: AnalyticsType) {
  selectedAnalytics.value = type
  router.push({ name: type })
  toggleTypeDropdown()
  onAnalyticsChange(type)
}
</script>
