<template>
  <div>
    <div
      id="menubar"
      class="h-14 flex items-center px-3 w-full justify-start text-slate-white bg-white dark:bg-tl-grey-100 fadein"
      @mouseup="state.menuIsVisible = false"
    >
      <div class="cursor-pointer" @click="navigateToDashboard()">
        <img class="h-9 block dark:hidden" :src="Logo" />
        <img class="h-9 hidden dark:block" :src="LogoBright" />
      </div>
      <div class="flex flex-grow"></div>

      <div class="mx-3 flex cursor-pointer dark:invert" @click="appStore.actions.toggleDarkMode()">
        <img class="w-3 mr-3" :src="appStore.getters.darkMode.value ? MoonIcon : SunIcon" />
      </div>
      <div class="flex justify-center items-center">
        <router-link
          class="text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200 px-3 py-2 rounded-3xl"
          to="/about"
          >{{ t('about') }}</router-link
        >
        <div
          v-if="isAdmin"
          class="text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200 px-3 py-2 rounded-3xl ml-2"
          @click="admin()"
        >
          {{ t('admin') }}
        </div>
        <div
          v-if="userStore.getters.user.value?._id"
          class="w-px h-9 dark:bg-white bg-tl-grey-100 mx-5"
        ></div>
        <div v-if="userStore.getters.user.value?._id">
          <div
            class="bg-tl-red-400 w-9 h-9 rounded-full flex justify-center items-center font-bold text-white"
            @click="state.menuIsVisible = !state.menuIsVisible"
          >
            {{ userStore.getters.user.value?.profile.username[0] }}
          </div>
          <div
            v-if="state.menuIsVisible"
            class="absolute z-50 right-2 top-16 bg-tl-grey-100 rounded-xl"
          >
            <SlButton class="" @click="logout()"> Logout </SlButton>
          </div>
        </div>
        <div>
          <!-- TODO: future language switch -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useUserStore } from '@/composition/stores/userStore'
import { useAppStore } from '@/composition/stores/appStore'

import SlButton from '@/components/base/SlButton.vue'
import MoonIcon from '@/assets/icons/fontawesome/moon.svg'
import SunIcon from '@/assets/icons/fontawesome/sun.svg'
import Logo from '@/assets/teamlearn-logo-black.png'
import LogoBright from '@/assets/teamlearn-logo-white.png'
import { UserRole, type User } from '@database'

const userStore = useUserStore()
const appStore = useAppStore()
const router = useRouter()

const messages = {
  no: { tasks: 'Oppgaver', about: 'Om', admin: 'Admin' },
  en: { tasks: 'Tasks', about: 'About', admin: 'Admin' }
}

const { t } = useI18n({ messages })

const state = reactive({
  user: {} as User,
  menuIsVisible: false,
  darkMode: false
})

onBeforeMount(() => {
  const user = userStore.getters.user.value
  if (user) state.user = user
})

const isAdmin = computed(() => {
  const user = userStore.getters.user.value
  return user ? user.profile.role === UserRole.admin : false
})

function admin() {
  if (router.currentRoute.value.fullPath.includes('legal')) router.push('/legal/admin')
  else router.push('/nursing/admin')
}

function navigateToDashboard() {
  if (isAdmin.value) router.push('/dashboard')
}

async function logout() {
  state.menuIsVisible = false
  await userStore.actions.logout()
  router.push(`/`)
}
</script>

<style scoped></style>
