/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

const serverHost = (import.meta.env.VITE_SERVER_HOST as string) || 'https://boilerplate.no'
const serverPort = import.meta.env.VITE_SERVER_PORT as string

let serverBaseUrl = `${serverHost}`

// Local development hosting requires the port for "hot reload" and for mobile app to server access over LAN
if (
  import.meta.env.DEV &&
  serverHost &&
  (serverHost.includes('localhost') || serverHost.includes('127.0.0.1'))
) {
  serverBaseUrl = `${serverHost}:${serverPort}`
}

const userRolesString = 'user,monitor,admin,logs'
const userRoles = userRolesString.split(',')

let appVersion: string = document.documentElement.getAttribute('data-appversion') || ''

// The web-based app will always be the latest version, set the version directly from .env
// If not built with Cordova, 'data-appversion' will === '%%VERSION%%'
if (appVersion === '%%VERSION%%') appVersion = (import.meta.env.VITE_VERSION as string) || ''

const deviceType: string = window.location.protocol == 'file:' ? 'mobile' : 'web'

enum AnalyticsType {
  MeetingAnalytics = 'MeetingAnalytics',
  CollaborationExperience = 'CollaborationExperience',
  WorkDocuments = 'WorkDocuments'
}

// User roles determine what is displayed on screen UI
enum USER_ROLE {
  user = 'user',
  monitor = 'monitor',
  admin = 'admin',
  logs = 'logs'
}
// Ensure enums match those defined in env file
const t = Object.values(USER_ROLE)
if (
  !userRoles.every((e: string) => t.indexOf(e as USER_ROLE) > -1) ||
  userRoles.length !== t.length
) {
  console.error('USER_ROLE enum mismatch', { t, userRoles })
}

// The keys used for i18n selection (except for 'system') should correlate to LanguageCodes (used for Squidex GraphQL calls)
enum LanguageNames {
  system = 'system', // System default
  //ar = 'العربية', // Arabic
  //bs = 'bosanski', // Bosnian
  //nb_NO = 'Norsk Bokmål',
  nn = 'Nynorsk',
  no = 'Norsk',
  sv = 'Svensk'
  /*  nn_NO = 'Norsk Nynorsk', */
  // en = 'English',
  //ku = 'Soranî', // Kurdish
  //pl = 'Polski', // Polish
  //so = 'af Soomaali', // Somali
  /*   sq = 'shqip', // Albanian */
  //ta = 'தமிழ்', // Tamil
  //tr = 'Türkçe',
  //ur = 'اردو', // Urdu
  /*  vi = 'tiếng việt', // Vietnamese */
}

export interface LF {
  [key: string]: Array<LanguageCodes>
}

export enum LanguageCodes {
  iv = 'iv', // Invariant code
  nn = 'nn',
  no = 'no',
  en = 'en',
  sv = 'sv'
}

// Note that every language must have a fallback defined!
export const LanguageFallbacks: LF = {
  en: [LanguageCodes.no],
  nn: [LanguageCodes.no],
  no: [],
  sv: [LanguageCodes.no]
}

// --------------- API -----------------

export enum XHR_REQUEST_TYPE {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE'
}

export enum XHR_CONTENT_TYPE {
  TEXT = 'text/html',
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded'
}

// Augment the Error class with message and status
export class HttpException extends Error {
  status: number
  message: string
  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }
}

export enum APIResponseCode {
  Ok = 200,
  Created = 201,
  NoContent = 204,

  Found = 302,
  NotModified = 304,

  BadRequest = 400,
  UnAuthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Gone = 410,
  TooLarge = 413
}

export const acceptedStates = [...Object.values(APIResponseCode)]
export const successStates = [
  APIResponseCode.Ok,
  APIResponseCode.Created,
  APIResponseCode.NoContent
]

export enum ErrorCode {
  LOGIN = '000',
  USER = '010',
  SQUIDEX = '180',
  SURVEY = '200',
  TEAMS = '300',
  NURSING = '600'
}

export enum PipelineStatus {
  Copying = 'Copying',
  Original = 'Original',
  Cleaned = 'Cleaned',
  Ready = 'Ready'
}

// ---------------- NURSING -----------------------

export const colours = ['#D6878B', '#9B7EB8', '#6FA8D3', '#7BAF94', '#FFFF00', '#00FF00', '#000']

export const columnDefs = [
  { name: 'LN', colour: colours[0] },
  { name: 'SN1', colour: colours[1] },
  { name: 'SN2', colour: colours[2] },
  { name: 'HR', colour: colours[3] },
  { name: 'S5', colour: colours[4] },
  { name: 'S6', colour: colours[5] },
  { name: 'LN - Mag', colour: colours[0] },
  { name: 'SN1 - Mag', colour: colours[1] },
  { name: 'SN2 - Mag', colour: colours[2] },
  { name: 'HR - Mag', colour: colours[3] },
  { name: 'S5 - Mag', colour: colours[4] },
  { name: 'S6 - Mag', colour: colours[5] }
]

export const colorsForActors = {
  LN: { index: 0, colour: colours[0] },
  SN1: { index: 1, colour: colours[1] },
  SN2: { index: 2, colour: colours[2] },
  HR: { index: 3, colour: colours[3] },
  S5: { index: 4, colour: colours[4] },
  S6: { index: 5, colour: colours[5] },
  Patient: { index: 6, colour: colours[6] },
  'LN - Mag': { index: 0, colour: colours[0] },
  'SN1 - Mag': { index: 1, colour: colours[1] },
  'SN2 - Mag': { index: 2, colour: colours[2] },
  'HR - Mag': { index: 3, colour: colours[3] },
  'S5 - Mag': { index: 4, colour: colours[4] },
  'S6 - Mag': { index: 5, colour: colours[5] }
}

export enum TabName {
  General = 'General',
  TaskCoordination = 'TaskCoordination',
  Communication = 'Communication',
  StressManagement = 'StressManagement'
}

export enum EventType {
  Loop = 'Loop',
  Task = 'Task',
  Stress = 'Stress',
  Event = 'Event',
  Key = 'Key'
}

export enum LoopCode {
  CallOut = 'Call-out',
  CheckBack = 'Check-back',
  Confirmation = 'Confirmation'
}

export enum TaskCode {
  TaskIdentification = 'Task Identification',
  TaskAllocation = 'Task Allocation',
  Compliance = 'Compliance'
}

export enum EventStatus {
  True = '1',
  False = '0',
  None = '-1'
}

export enum Speaker {
  LeadNurse = 'LN',
  SupportNurse1 = 'SN1',
  SupportNurse2 = 'SN2',
  HandoverResponsible = 'HR',
  S5 = 'S5',
  S6 = 'S6',
  Patient = 'Patient',
  None = 'None',
  All = 'All'
}

export const SpeakerColour = {
  [Speaker.LeadNurse]: '#B1225D',
  [Speaker.SupportNurse1]: '#8D69BF',
  [Speaker.SupportNurse2]: '#036DAE',
  [Speaker.HandoverResponsible]: '#7E9F74',
  none: '#BFBFBF'
}

// ---------------- LEGALTECH ---------------------

export const TEAMS = ((import.meta.env.VITE_TEAMS as string) || '').split(',')

export enum NETTSKJEMA_QUESTIONS {
  cBalancedParticipation = 'Our group has been able to secure balanced participation in group discussions',
  cBalancedContribution = 'Our group has been able to secure balanced contributions to the project from all members',
  cValuedContribution = 'My contributions are being valued by the group',
  cTaskAllocation = 'Our group agreed on the allocation of tasks',
  cExpectations = 'I understand what is expected of me by others in the group',
  cNegotiation = 'Our group members are able to freely express their feelings & opinions about the project',
  cCommunication = 'Our group is communicating well about the project work ',
  cSharedIdeas = 'In our group we build on each others ideas to develop the project',
  cReflections = 'Our group regularly reflects on the progress of the project',
  cResolveConflicts = 'Our group has been able to resolve conflicts & misunderstandings'
}

export enum CASES {
  'nursing' = 'nursing',
  'medical' = 'medical',
  'legal' = 'legal',
  'gpt' = 'gpt'
}

export enum ROLES {
  'teacher' = 'teacher',
  'student' = 'student'
}
// export enum CHATNAMES {
//   'legaltech1' = 'legaltech1 Chat',
//   'legaltech2' = 'legaltech2 Chat',
//   'legaltech3' = 'legaltech3 Chat',
//   'legaltech4' = 'legaltech4 Chat',
// }
export enum TITLE {
  'messages' = 'Messages',
  'reactions' = 'Reactions',
  'attachments' = 'Attachments',
  'mentions' = 'Mentions'
}
export enum SORTTYPE {
  'title' = 'Title',
  'date' = 'Date'
}

export enum STUDENT_COLOURS {
  'Student 1' = '#F7A23B',
  'Student 2' = '#00C7F2',
  'Student 3' = '#0FCA7A',
  'Student 4' = '#A47DFF',
  'Student 5' = '#444444',
  'Student 6' = '#444444',
  'Student 7' = '#444444'
}
export const ADMINS = [`sharanym`, `rogerska`, `janad`]

export const RATING_SEQUENCE = [
  { 1: 'Completely disagree' },
  { 2: 'Disagree' },
  { 3: 'Neither agree nor disagree' },
  { 4: 'Agree' },
  { 5: 'Completely agree' }
]

export enum PROMPTYPE {
  Document = 'Document',
  Meeting = 'Meeting'
}

export const title = ['Messages', 'Messages', 'Reactions', 'Attachments']

export enum PROMPTSECTION {
  Title = 'Title',
  Topics = 'Topics',
  Items = 'Items',
  Feedback = 'Feedback',
  Summary = 'Summary'
}

export const promptSectionsMeetings = ['Title', 'Topics', 'Items'] // This order is important and should not be changed.

// CMS
export const cmsUrl: string = import.meta.env.VITE_APP_CMS_HOST || ''
export const assetRoot: string = import.meta.env.VITE_APP_CMS_HOST + '/api/assets' || ''
export const cmsClientId = import.meta.env.VITE_APP_SQUIDEX_CLIENT_ID || ''
export const cmsClientSecret = import.meta.env.VITE_APP_SQUIDEX_CLIENT_SECRET || ''
export const cmsTokenUrl: string = cmsUrl + '/identity-server/connect/token'

export { deviceType, serverBaseUrl, userRoles, USER_ROLE, appVersion, LanguageNames, AnalyticsType }
