import type { IDocumentMetadataResponse, ISpeakinginfo, IVersionInfo, Speakinginfo } from '@teams'
import type { CmsGQLData } from './main.js'

export interface MessageResponse {
  messages: MessageData[]
}
export interface MessageData {
  createdDateTime: string
  userName: string
  mentionsCount: number
  reactionsCount: number
  attachmentsCount: number
  postCount: number
}

export interface IMessageAggregate {
  createdDateTime: string
  'from.user.displayName': string
  mentionsCount: number
  reactionsCount: number
  attachmentsCount: number
  'body.content': number
}
export interface IPostCount {
  createdDateTime: string
  'from.user.displayName': string
  postCount: number
}
export interface IMessageResponse {
  messageAggregate: IMessageAggregate[]
  postCount: IPostCount[]
}
export class Message {
  messageAggregate: IMessageAggregate[]
  postCount: IPostCount[]
  constructor(data: IMessageResponse) {
    this.messageAggregate = data.messageAggregate || []
    this.postCount = data.postCount || []
  }
}

export interface MemberData {
  userId: string
  displayName: string
  email: string
  tenantId: string
}
export interface ChannelData {
  id: string
  displayName: string
  email: string
  tenantId: string
}
export class Channel {
  channel_id: string
  displayName: string
  email: string
  tenantId: string
  constructor(data: ChannelData) {
    this.channel_id = data.id
    this.email = data.email
    this.displayName = data.displayName
    this.tenantId = data.tenantId
  }
}

export interface ISpeakingInfo {
  speaker: string
  count: string
  duration: string
  duration_percent?: string
}

export class SpeakingInfo {
  speaker: string
  count: number
  duration: number
  duration_percent: number
  constructor(data: ISpeakingInfo) {
    this.speaker = data.speaker
    this.count = Math.round(Number(data.count) * 100) / 100
    this.duration = Math.round(Number(data.duration) * 100) / 100
    this.duration_percent = Math.round(Number(data.duration_percent) * 100) / 100
  }
}

export interface IMeetingInfo {
  speakinginfo: SpeakingInfo[]
  filename: string
  filefeedback?: string
  createdDate: string
}

export class Member {
  member_id: string
  profile: {
    email: string
    displayName: string
    tenantId: string
  } = {
    email: '',
    displayName: '',
    tenantId: ''
  }
  constructor(data: MemberData) {
    this.member_id = data.userId
    this.profile = {
      email: data.email,
      displayName: data.displayName,
      tenantId: data.tenantId
    }
  }
}

export interface IDocumentFeedback {
  filename: string
  filecontent: string
  filefeedback: string
  section: string
}

export class DocumentFeedback {
  filename: string
  filecontent: string
  filefeedback: string
  section: string
  constructor(data: IDocumentFeedback) {
    ;(this.filename = data.filename),
      (this.filecontent = data.filecontent),
      (this.filefeedback = data.filefeedback),
      (this.section = data.section)
  }
}

export class AggVersionByUser {
  key: string
  value: number
  constructor(data: Record<string, number>) {
    this.key = Object.keys(data)[0]
    this.value = data[Object.keys(data)[0]]
  }
}

export class DocumentMetadata {
  totalUploads: Number
  totalModifications: Number
  aggVersionbyUser: Record<string, number>
  aggUploadbyUser: Record<string, number>
  versionInfo: IVersionInfo[]

  constructor(data: IDocumentMetadataResponse) {
    this.totalUploads = data.totalUploads
    this.totalModifications = data.totalModifications
    this.aggVersionbyUser = data.aggVersionbyUser
    this.aggUploadbyUser = data.aggUploadbyUser
    this.versionInfo = data.versionInfo
  }
}

export interface IPromptSchema extends CmsGQLData {
  id: string
  instruction: {
    iv: string
  }
  prompt: {
    iv: string
  }
  explanation: {
    iv: string
  }
  promptSection: {
    iv: string
  }
  promptType: {
    iv: string
  }
}
export class PromptSchema {
  id: string
  instruction: string
  prompt: string
  explanation: string
  promptSection: string
  promptType: string
  constructor(schema: IPromptSchema) {
    this.id = schema.id || ''
    this.instruction = schema.instruction.iv || ''
    this.prompt = schema.prompt.iv || ''
    this.explanation = schema.explanation.iv || ''
    this.promptSection = schema.promptSection.iv || ''
    this.promptType = schema.promptType.iv || ''
  }
}

// ------------------   Survey ----------------------

interface AnswerOptions {
  answerOptionId: number
  correct: boolean
  externalAnswerOptionId: string
  preselected: boolean
  sequence: number
  text: string
}

interface Answer {
  answerId: number
  answerOptions: Array<AnswerOptions>
  externalQuestionId: string
  questionId: number
  textAnswer?: string
}

interface Question {
  description: string
  elementType: string
  externalQuestionId?: string
  questionId: number
  sequence: number
  text: string
}

interface Form {
  title?: string
  elementId: number
  description?: string
  elementType: string
  questions: Array<Question>
  sequence: number
}

export interface ReviewForm {
  title: string
  elements: Array<Form>
  answers: Array<Array<Answer>>
}

export interface Nettskjema {
  studentsReview: ReviewForm
  teachersReview: ReviewForm
}

export interface Team {
  id: string
  title: string
}

export interface Chat {
  title: string
}

export interface ITurn {
  speaker: string
  count: number
}

export interface IDuration {
  speaker: string
  duration: number
}

export interface IDurationPercent {
  speaker: string
  duration_percent: number
}

export interface IPieData {
  key: string
  value: number
}

export interface IAgg extends ISpeakinginfo {
  'duration%': number
  'count%': number
}

export class Turns {
  key: string
  value: number
  constructor(data: Speakinginfo) {
    this.key = data.speaker || ''
    this.value = data.count || 0
  }
}

export class Duration {
  key: string
  value: number
  constructor(data: Speakinginfo) {
    this.key = data.speaker || ''
    this.value = data.duration || 0
  }
}

export class DurationPercent {
  key: string
  value: number
  constructor(data: Speakinginfo) {
    this.key = data.speaker || ''
    this.value = Math.round(data.duration_percent) || 0
  }
}

export class Agg {
  speaker: string
  count: string
  duration: string
  'duration%': number
  'duration_percent'?: string
  'count%': number
  constructor(data: IAgg) {
    this['duration%'] = data['duration%']
    this['count%'] = data['count%']
    this.speaker = data.speaker
    this.count = data.count
    this.duration = data.duration
    this.duration_percent = data.duration_percent || ''
  }
}

export class AggDuration {
  key: string
  value: number
  constructor(data: Agg) {
    this.key = data.speaker || ''
    this.value = Math.round(data['duration%']) || 0
  }
}

export interface IContribution {
  'from.user.displayName': string
  'body.content': number
}
export class Contribution {
  key: string
  value: number
  constructor(data: IContribution) {
    this.key = data['from.user.displayName'] || ''
    this.value = data['body.content'] || 0
  }
}

export interface IAggRes {
  externalQuestionId: string
  mean: number
}

export interface IAnswer {
  externalQuestionId: string
  questionId: string
  userName: string
  groupId: string
  nettskjemaId: string
  text: string
  sequence: number
  mean: number
}

export interface ISubmission {
  groupId: string
  answers: IAnswer[]
  aggAnswers: Record<string, IAnswer>
}

export interface IPath {
  selectedTeam: ''
  selectedMode: ''
}
