// useCMSStore are the same across projects,
// however they provide the junction to each project's unique data structures in neighboring directories
import { ref, computed } from 'vue'
import type { Ref, ComputedRef } from 'vue'
import { cmsRequest } from '@/api/cmsService.js'
import useDialogStore from '@/composition/dialog.js'
import type { CmsGQLQuery, CmsGQLData, IPromptSchema } from '@frontend'
import { PromptSchema } from '@frontend'
import { LanguageCodes } from '@/constants.js'

/* const prompt = `query prompt {
  results: queryPromptschemaContents {
    id
    data {
      instruction {
        iv
      }
      explanation {
        iv
      }
      prompt {
        iv
      }
      promptType {
        iv
      }
    }
  }
}` */

const prompt = `query prompt {results: queryPromptschemaContents {id data {instruction {iv}explanation {iv}prompt {iv}promptSection {iv}promptType {iv}}}}`

const dialogStore = useDialogStore()

// ------------  State (internal) --------------
interface State {
  promptSchema: Map<string, PromptSchema>
  selectedDocumentPrompt: PromptSchema | undefined
  // selectedMeetingPrompt:PromptSchema|undefined
}

const state: Ref<State> = ref({
  promptSchema: new Map(),
  selectedDocumentPrompt: undefined
  // selectedMeetingPrompt:undefined
})
// ------------  Internal functions ------------

async function fetchPromptSchema(): Promise<CmsGQLQuery | undefined> {
  // const variables = {}
  return cmsRequest('legaltech', prompt, {}, LanguageCodes.en)
}
// ------------  Getters --------------
interface Getters {
  prompts: ComputedRef<Map<string, PromptSchema>>
  documentPrompt: ComputedRef<PromptSchema | undefined>
}

const getters = {
  get prompts(): ComputedRef<Map<string, PromptSchema>> {
    return computed(() => state.value.promptSchema)
  },
  get documentPrompt(): ComputedRef<PromptSchema | undefined> {
    return computed(() => state.value.selectedDocumentPrompt)
  }
}

// ------------  Actions --------------
interface Actions {
  getPrompts: () => Promise<Map<string, PromptSchema>>
}
const actions = {
  getPrompts: async function (): Promise<Map<string, PromptSchema>> {
    const response = await fetchPromptSchema()
    if (response && response.data) {
      const results = response.data.results as CmsGQLData[]
      results.map((r) => {
        const obj = { id: r.id, ...r.data }
        const promptData = new PromptSchema(obj as unknown as IPromptSchema)
        state.value.promptSchema.set(promptData.promptType + promptData.promptSection, promptData)
      })
    } else {
      const error = 'Sett query contains no records'
      dialogStore.actions.pushMessage(error)
      console.log(error)
    }

    return Promise.resolve(state.value.promptSchema)
  }
}

// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useCMSStore(): ServiceInterface {
  return {
    getters,
    actions
  }
}

export type CMSStoreType = ReturnType<typeof useCMSStore>
// export const UserKey: InjectionKey<UseUser> = Symbol('UseUser')
