<template>
  <div class="h-screen w-screen flex flex-col p-12 bg-white dark:bg-tl-grey-100">
    <div class="w-1/2">
      <h3 class="mt-16 text-tl-lilla-400">Welcome to Teamlearn</h3>
      <h1 class="text-7xl text-black dark:text-white">
        Track and analyze your team's collaboration
      </h1>
      <p class="text-tl-grey-300">
        Welcome to the analytics dashboard for students in TeamLearn research project. Please, log
        in to gain insight into your group’s collaboration on the project report in Teams in the
        course Legal Technology
      </p>
      <br />
      <div class="cursor-pointer bg-tl-lilla-400 text-black w-24 p-2 text-center rounded-xl h-10">
        <p @click="login()">Logg in</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LoginType } from '@/interfaces'
import { serverBaseUrl } from '@/constants'

function login() {
  window.location.href = `${serverBaseUrl}/auth/${LoginType.Feide}`
}
</script>
